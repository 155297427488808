import { Clear, Search } from "@mui/icons-material";
import { Box, Button, FormControl } from "@mui/material";
import PivotGrid, {
    Export,
    FieldChooser,
    FieldPanel,
    Scrolling,
} from "devextreme-react/pivot-grid";
import { exportPivotGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import Loader from "../components/loader";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";

const StockPriceCompareView = (props) => {
    const pivotGridRef = useRef(null);
    const masterFillAPI = usePostData(`/master_fill`);
    const rapViewAPI = usePostData(`/stock_price_compare_view`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const [data, setData] = useState(null);
    const [columns, setColumn] = useState(null);
    const [toastMess, setToastMess] = useState(null);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        other_company_name: [],
        shape_code: [],
        cut_code: [],
        pol_code: [],
        sym_code: [],
        color_shade_code: [],
        color_name: [],
        clarity_name: [],
        size: [],
        lab_code: [],
        location_code: [],
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const keys = [
        "shape_code",
        "cut_code",
        "pol_code",
        "sym_code",
        "color_shade_code",
        "lab_code",
        "location_code",
    ];
    const colorValue = options?.color_name?.map((item) => item?.value) || [];
    const shapeValue = options?.shape_code?.map((item) => item?.id) || [];
    const clarityValue = options?.clarity_name?.map((item) => item?.value) || [];
    const sizeValue = options?.size?.map((item) => item?.value) || [];

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                mast_type: mastType,
                mast_name: mastName,
            });

            // Ensure unique options
            const uniqueData = Array.from(
                new Map(responseData.map(item => [item.label, item])).values()
            );

            return { [key]: uniqueData };
        } catch (error) {
            console.error("Error:", error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        setLoading(true)
        try {
            const [otherCompanyName, shapCode, cutCode, polishCode, symCode, colorShadeCode, labCode, locationCode, colorName, clarityName, size] = await Promise.all([
                fetchData("select", "other_company", "other_company_name"),
                fetchData("select", "shape", "shape_code"),
                fetchData("select", "cut", "cut_code"),
                fetchData("select", "pol", "pol_code"),
                fetchData("select", "sym", "sym_code"),
                fetchData("select", "color_shade", "color_shade_code"),
                fetchData("select", "color", "color_name"),
                fetchData("select", "clarity", "clarity_name"),
                fetchData("select", "size", "size"),
                fetchData("select", "lab", "lab_code"),
                fetchData("select", "diamond_location", "location_code"),
            ]);
            setLoading(false);
            setOptions({ ...otherCompanyName, ...shapCode, ...cutCode, ...polishCode, ...symCode, ...colorShadeCode, ...labCode, ...locationCode, ...colorName, ...clarityName, ...size });
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const fetchColumn = async () => {
        setLoading(true);
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "stock_price_compare_view"
            });
            setColumn(responseData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    function getColorFromScale(lowestValue, highestValue, value) {
        // Define the Excel-like gradient from green to yellow to red
        const colorScale = [
            { r: 99, g: 190, b: 123 },    // Green (low value)
            { r: 255, g: 235, b: 132 },  // Yellow
            { r: 248, g: 105, b: 107 },    // Red (high value)
        ];

        // If the lowestValue and highestValue are the same, use the color for the lowest value
        if (lowestValue === highestValue) {
            return "";
        }

        // Normalize the value between 0 and 1, based on the lowest and highest values
        const normalizedValue = Math.min(Math.max((value - lowestValue) / (highestValue - lowestValue), 0), 1);

        // Determine the segment where the value falls (color interpolation)
        const segmentCount = colorScale.length - 1;
        const segmentIndex = Math.floor(normalizedValue * segmentCount);
        const nextSegmentIndex = Math.min(segmentIndex + 1, segmentCount);

        // Interpolate between the start and end colors for the segment
        const startColor = colorScale[segmentIndex];
        const endColor = colorScale[nextSegmentIndex];
        const segmentProgress = (normalizedValue * segmentCount) - segmentIndex;

        const r = Math.round(startColor.r * (1 - segmentProgress) + endColor.r * segmentProgress);
        const g = Math.round(startColor.g * (1 - segmentProgress) + endColor.g * segmentProgress);
        const b = Math.round(startColor.b * (1 - segmentProgress) + endColor.b * segmentProgress);

        // Convert RGB to Hex and return the color
        return rgbToHex({ r, g, b });
    }

    // Helper function to convert RGB to Hex
    function rgbToHex({ r, g, b }) {
        return `${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    }

    const fetchRowData = async () => {
        setLoading(true);
        try {
            const responseData = await rapViewAPI.mutateAsync({
                company_id: formData?.company_id?.map(_ => _?.value)?.join(","),
                shape_code: formData?.shape_code?.map(_ => _?.id)?.join(","),
                cut_code: formData?.cut_code?.map(_ => _?.id)?.join(","),
                pol_code: formData?.pol_code?.map(_ => _?.id)?.join(","),
                sym_code: formData?.sym_code?.map(_ => _?.id)?.join(","),
                color_shade_code: formData?.color_shade_code?.map(_ => _?.id)?.join(","),
                lab_code: formData?.lab_code?.map(_ => _?.id)?.join(","),
                location_code: formData?.location_code?.map(_ => _?.id)?.join(","),
            });
            setData(responseData)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAllData();
        fetchColumn();
    }, []);

    const handleChange = (newValue, name) => {
        setFormData({ ...formData, [name]: newValue?.target?.value });
        setError("")
    }

    const handleSelectionChange = (newValue) => {
        const selectedValues = newValue.map(option => option);
        handleChange({ target: { value: selectedValues } }, "company_id");
    };

    const handleOtherSelectionChange = (newValue, name) => {
        const selectedValues = newValue.map((option) => option);
        handleChange({ target: { value: selectedValues } }, name);
    };

    const clearForm = () => {
        setFormData({}); // Clear all form selections
        setData(null);   // Clear the grid data
        setError("");    // Reset any error messages
    };

    const summaryDisplayModes = [
        { text: "None", value: "none" },
        { text: "Absolute Variation", value: "absoluteVariation" },
        { text: "Percent Variation", value: "percentVariation" },
        { text: "Percent of Column Total", value: "percentOfColumnTotal" },
        { text: "Percent of Row Total", value: "percentOfRowTotal" },
        { text: "Percent of Column Grand Total", value: "percentOfColumnGrandTotal" },
        { text: "Percent of Row Grand Total", value: "percentOfRowGrandTotal" },
        { text: "Percent of Grand Total", value: "percentOfGrandTotal" },
    ];

    const fields = columns && columns.map((col) => {
        const validSummaryDisplayMode = summaryDisplayModes.some(
            (mode) => mode.value === col.summary_type
        )
            ? col.summary_type
            : "none"; // Default to "none" if invalid or missing

        return {
            ...col,
            area: col.field_area,
            dataField: col.column_name,
            caption: col.column_header,
            area: col.field_area,
            dataType: col.column_type === "text" ? "string" : "number",
            format: col.column_format,
            width: col.column_width,
            alignment: col.column_align === 1 ? "left" : "right",
            visible: col.is_visible,
            summaryDisplayMode: validSummaryDisplayMode,
            allowSorting: true,
            allowFiltering: true,
            allowExpandAll: true,
            ...(col?.column_format && { format: { type: 'fixedPoint', precision: col?.column_format } }),
            customizeText: (e) => {
                return col.is_text_upper ? e.valueText?.toUpperCase() : e.valueText;
            },
            summaryType: validSummaryDisplayMode?.includes("percent") ? "sum" : col.summary_type || null,
            expanded: true,
        };
    });

    const onExporting = (e) => {
        if (data?.length) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("PivotGrid");

            e.cancel = true; // Cancel default export to use custom implementation

            exportPivotGrid({
                component: pivotGridRef.current.instance,
                worksheet,
                customizeCell: ({ pivotCell, excelCell }) => {
                    if (isDataCell(pivotCell) && pivotCell.text) {
                        const appearance = getConditionalAppearance(pivotCell);
                        if (appearance) {
                            excelCell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: appearance.replace('#', '') },
                                colorValue: "#FFFFFF",
                            };
                        }
                    }
                },
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "PivotGrid.xlsx"
                    );
                });
            });
        }
    };

    const isDataCell = (cell) => (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');
    const isTotalCell = (cell) => (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');

    const getConditionalAppearance = (cell) => {
        if (cell.text) {
            const rowPath = cell.rowPath;
            const colPath = cell.columnPath;

            const shapeRow = shapeValue.filter(value => rowPath.includes(value))
            const sizegroupRow = sizeValue.filter(value => rowPath.includes(value))
            const colorRow = colorValue.filter(value => rowPath.includes(value))
            const clarityRow = clarityValue.filter(value => rowPath.includes(value))

            const shapeColumn = shapeValue.filter(value => colPath.includes(value))
            const sizegroupColumn = sizeValue.filter(value => colPath.includes(value))
            const colorColumn = colorValue.filter(value => colPath.includes(value))
            const clarityColumn = clarityValue.filter(value => colPath.includes(value))

            const minMax = data?.filter(_ => _?.sizegroup_code === (sizegroupRow[0] || sizegroupColumn[0]) && _?.sub_shape_code === (shapeRow[0] || shapeColumn[0]) && _?.color_name === (colorRow[0] || colorColumn[0]) && _?.clarity_name === (clarityRow[0] || clarityColumn[0]))
            const highestAverageRate = minMax?.[0]?.max_rate;
            const lowestAverageRate = minMax?.[0]?.min_rate;
            return getColorFromScale(lowestAverageRate, highestAverageRate, cell.value);
        }
    };

    const onCellPrepared = (e) => {
        const { cell, area, cellElement } = e;
        cell.area = area;

        // for data cell only and if they have values within it
        if (isDataCell(cell) && cell.text) {
            const appearance = getConditionalAppearance(cell);
            if (!appearance) {
                return;
            }

            Object.assign(cellElement.style, getCssStyles({ fill: appearance }));
        }
    };

    const getCssStyles = ({ fill, font, bold }) => ({
        'background-color': `#${fill}`,
        color: `#${font}`,
        'font-weight': bold ? 'bold' : undefined,
    });

    return (
        <React.Fragment>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                <div className="d_row" style={{ width: "90%", gap: 3 }}>
                    <div style={{ maxWidth: "160px", width: "100%" }}>
                        <FormControl fullWidth>
                            <MultipleSelectCheckmarks
                                options={options?.other_company_name}
                                value={formData?.company_id || []}
                                handleChange={handleSelectionChange}
                                headerName={"Party"}
                            />
                        </FormControl>
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </div>
                    {keys.map((key) => (
                        <div style={{ maxWidth: "160px", width: "100%" }} key={key}>
                            <FormControl fullWidth>
                                <MultipleSelectCheckmarks
                                    options={options[key] || []}
                                    value={formData[key] || []}
                                    handleChange={(newValue) => handleOtherSelectionChange(newValue, key)}
                                    headerName={key.replace("_code", "").replace(/_/g, " ").toUpperCase()}
                                />
                            </FormControl>
                            {error && <em style={{ color: "red" }}>{error}</em>}
                        </div>
                    ))}
                </div>
                <div className="d_flex" style={{ gap: "10px", alignItems: "flex-start" }}>
                    <Button variant="contained" onClick={() => fetchRowData()} disabled={loading} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
                    <Button variant="contained" onClick={clearForm} disabled={loading} startIcon={<Clear />}> Clear </Button>
                </div>
            </Box>
            {!loading ?
                <PivotGrid
                    ref={pivotGridRef}
                    dataSource={{
                        fields: fields,
                        store: data,
                    }}
                    allowSortingBySummary={true}
                    allowFiltering={true}
                    showBorders={true}
                    height="calc(90% - 50px)"
                    rowHeaderLayout="standard"
                    className="d_rap-view d_stock-comapre"
                    onExporting={onExporting}
                    showRowTotals={false}
                    showColumnTotals={false}
                    onCellPrepared={onCellPrepared}
                    showRowGrandTotals={false}
                    showColumnGrandTotals={false}
                    loadPanel={{ enabled: true }}
                >
                    <Scrolling mode="virtual" />
                    <Export enabled={true} />
                    <FieldChooser enabled={true} />
                    <FieldPanel showColumnFields={false} showDataFields={false} showFilterFields={true} allowFieldDragging={true} visible={true} />
                </PivotGrid>
                : <Loader />
            }
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    )
};

export default StockPriceCompareView;