import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import VirtualizedTableData from "../components/VirtualizedTableData";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import { CloudSync, Search, Sync } from "@mui/icons-material";
import ToastBar from "../components/ToastBar";

const PartyStockNotMatchParameter = (props) => {
    const masterFillAPI = usePostData(`/master_fill`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/party_stock_not_match_parameter_view`);
    const syncDataAPI = usePostData(`/party_stock_not_match_parameter_update`);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        other_company_name: []
    });
    const [visibleColumn, setVisibleColumn] = useState({});
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState("");
    const [toastMess, setToastMess] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSync, setIsSync] = useState(false);
    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                mast_type: mastType,
                mast_name: mastName,
            });

            // Ensure unique options
            const uniqueData = Array.from(
                new Map(responseData.map(item => [item.label, item])).values()
            );

            return { [key]: uniqueData };
        } catch (error) {
            console.error("Error:", error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [otherCompanyName] = await Promise.all([
                fetchData("select", "other_company", "other_company_name"),
            ]);
            setOptions({ ...otherCompanyName });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "party_stock_not_match_parameter",
            });

            const getBackgroundColor = (colName, rowData) => {
                // List of keys to ignore for background color changes
                const ignoreFields = ["company_name", "diamond_id"];
                // Check if the value is non-empty and the column is not in the ignore list
                if (!ignoreFields.includes(colName) && rowData[colName] !== "" && rowData[colName] !== null) {
                    const paraBackColor = rowData.para_back_color;
                    // Use para_back_color if available
                    return paraBackColor ? `rgb(${paraBackColor})` : "";
                }
                return "";
            };

            const columns = responseData?.map((col) => ({
                accessorKey: col?.column_name,
                header: col?.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
                is_editable: col?.is_editable,
                getBackgroundColor: (rowData) => getBackgroundColor(col?.column_name, rowData),
            }));

            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const fetchRowData = async (company_id) => {
        setLoading(true)
        try {
            const responseData = await masterViewAPI.mutateAsync({
                company_id: company_id?.map(_ => _?.value)?.join(",")
            });

            if (responseData) {
                const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({ ...row, })) : [];
                setRows(rowsData);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const syncRowData = async (company_id) => {
        setIsSync(true)
        try {
            const responseData = await syncDataAPI.mutateAsync({
                company_id: company_id?.map(_ => _?.value)?.join(",")
            });
            setIsSync(false)
            setToastMess(responseData?.message);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAllData();
        fetchColumn();
        fetchRowData();
    }, []);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    const handleChange = (newValue, name) => {
        setFormData({ ...formData, [name]: newValue?.target?.value });
        setError("")
    }

    // For Footer calculation
    const sumRow = {};
    const numberColumns = ['company_name']
    sumRow['company_name'] = rows?.length;

    const handleSelectionChange = (newValue) => {
        const selectedValues = newValue.map(option => option);
        handleChange({ target: { value: selectedValues } }, "company_id");
    };

    return (
        <React.Fragment>
            <Box sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="d_row" style={{ width: "100%" }}>
                    <div className="d_col-3">
                        <FormControl fullWidth>
                            <MultipleSelectCheckmarks
                                options={options?.other_company_name}
                                value={formData?.company_id || []}
                                handleChange={handleSelectionChange}
                                headerName={"Party"}
                            />
                        </FormControl>
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </div>
                </div>
                <div className="d_flex-align" style={{gap: "10px"}}>
                    <Button variant="contained" onClick={() => fetchRowData(formData?.company_id)} disabled={loading} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
                    <Button variant="contained" onClick={() => syncRowData(formData?.company_id)} disabled={isSync} startIcon={<CloudSync />} sx={{ ...(isSync && { color: "white !important" }) }}>{isSync ? "Syncing..." : "Sync"}</Button>
                </div>
            </Box>

            {(columnResponse && visibleColumn !== null) &&
                <VirtualizedTableData
                    style={{ margin: 0 }}
                    height={`calc(100vh - 185px)`}
                    data={rows} visibleColumn={visibleColumn}
                    loading={!viewColumnsFillAPI?.isPending}
                    columnResponse={columnResponse} memoSumRow={sumRow}
                    numberColumns={numberColumns}
                    rowSelection={false} rowCount={rows?.length}
                />
            }
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    )
};

export default PartyStockNotMatchParameter;
