import { Delete, Edit, PhotoSizeSelectActualOutlined, VideocamOutlined, ViewColumn, WorkspacePremiumOutlined } from '@mui/icons-material';
import { Box, Button, FormControlLabel, IconButton, MenuItem, Popover, Switch, Tooltip } from '@mui/material';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { COLOR } from '../common/constant';
import ExportExcel from '../common/icons/ExportExcel';
import CertificateModal from './CertificateModal';
import Loader from './loader';
import ImgVideoModal from './diamond-img-video-modal';

const VirtualizedTableData = (props) => {
    const { className, isSticky, columnResponse = [], data, height, onSelectedRowsChange, rowCount, loading, visibleColumn, rowSelection, numberColumns = [], style, handleDelete, handleEdit, totalCountColumn, tfoot = true, selectedId, hideDelete, hideEdit, uploadExcel, clearSelection, extraButtons, isGallery, ischeckBoxEdit = false, handleCheckboxChange, memoSumRow } = props;
    const [selectedDiamondIds, setSelectedDiamondIds] = useState(new Set());
    const [columnSearchQueries, setColumnSearchQueries] = useState(
        Array(columnResponse.length).fill('')
    );
    const [showColumnSearch, setShowColumnSearch] = useState(false);
    const [prevSelected, setPrevSelected] = useState(new Set());
    const [visibleColumns, setVisibleColumns] = useState(
        Object.keys(visibleColumn).filter(key => visibleColumn[key])
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const [certificate, setCertificate] = useState('');
    const [imgVideoUrl, setImgVideoUrl] = useState({});

    const filteredData = useMemo(() => {
        return data.filter((row) =>
            columnResponse.every((col) => {
                const searchQuery = columnSearchQueries[col.accessorKey] ? columnSearchQueries[col.accessorKey].toLowerCase() : '';
                return (
                    searchQuery === '' ||
                    String(row[col.accessorKey] || '').toLowerCase().includes(searchQuery)
                );
            })
        );
    }, [data, columnSearchQueries, columnResponse]);

    useEffect(() => {
        const selectedArray = [...selectedDiamondIds];
        if (selectedArray.length !== prevSelected.size || !Array.from(prevSelected).every(id => selectedArray.includes(id))) {
            onSelectedRowsChange(selectedArray);
            setPrevSelected(new Set(selectedArray));
        }
    }, [selectedDiamondIds, onSelectedRowsChange]);

    useEffect(() => {
        if (clearSelection) {
            setSelectedDiamondIds(new Set()); // Clear the selected diamond IDs
        }
    }, [clearSelection]);

    const tableContainerRef = useRef(null);
    const rowVirtualizer = useVirtualizer({
        count: filteredData.length,
        estimateSize: () => 33,
        getScrollElement: () => tableContainerRef.current,
        overscan: 1,
    });

    const table = useReactTable({
        data: filteredData,
        columns: columnResponse.filter(col => visibleColumns.includes(col.accessorKey)),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableRowSelection: true,
        onRowSelectionChange: setSelectedDiamondIds,
    });

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = new Set(filteredData.map(row => row[selectedId]));
            setSelectedDiamondIds(allIds);
        } else {
            setSelectedDiamondIds(new Set());
        }
    };

    const isAllSelected = filteredData.length > 0 && filteredData.every(row => selectedDiamondIds.has(row[selectedId]));
    const isSomeSelected = filteredData.some(row => selectedDiamondIds.has(row[selectedId]));

    const handleColumnVisibilityChange = (columnAccessorKey) => {
        setVisibleColumns(prev =>
            prev.includes(columnAccessorKey)
                ? prev.filter(key => key !== columnAccessorKey)
                : [...prev, columnAccessorKey]
        );
    };

    const handle = {
        showAll: () => {
            setVisibleColumns(columnResponse.map(col => col.accessorKey));
        },
        hideAll: () => {
            setVisibleColumns([]);
        },
        click: (event) => {
            setAnchorEl(event.currentTarget);
        },
        close: () => {
            setAnchorEl(null);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'column-visibility-popover' : undefined;

    const exportToExcel = () => {
        // Create headers based on visible columns, excluding action columns
        const headers = columnResponse
            .filter(col => visibleColumns.includes(col.accessorKey) && col.columnType !== 'action')
            .map(col => col.header); // Use the display name (header)

        // Map filteredData to rows matching the order of headers
        const exportData = filteredData.map(row =>
            headers.reduce((acc, header) => {
                const col = columnResponse.find(col => col.header === header);
                acc[header] = row[col.accessorKey]; // Match header to its accessorKey
                return acc;
            }, {})
        );

        // Calculate footer summary based on visible columns
        const footerSummary = headers.reduce((acc, header) => {
            const accessorKey = columnResponse.find(col => col.header === header)?.accessorKey;
            const isNumberColumn = numberColumns.includes(accessorKey);

            // Add sumRow values for number columns or leave empty for others
            acc[header] = isNumberColumn ? memoSumRow[accessorKey] || '' : '';
            return acc;
        }, {});

        // Add footer summary to the exported data
        exportData.push(footerSummary);

        // Create worksheet with headers and rows
        const worksheet = XLSX.utils.json_to_sheet(exportData, { header: headers });

        // Create workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

        // Export the workbook as an Excel file
        XLSX.writeFile(workbook, `Table Data ${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}.xlsx`);
    };

    const isStickyColumn = (columnIndex) => isSticky && columnIndex < 9;

    return (
        <React.Fragment>
            <div className={`virtualized-table ${className}`} style={{ ...style }}>
                <div className="virtualized-table-filter" style={{ padding: "5px" }}>
                    {uploadExcel}
                    <div className="d_flex-align d_content-end d_w-full">
                        <FormControlLabel
                            control={<Switch checked={showColumnSearch} onChange={() => setShowColumnSearch(prev => !prev)} />}
                            label="Column Search"
                        />
                        <IconButton onClick={handle?.click}>
                            <ViewColumn />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handle?.close}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                            <div style={{ padding: '10px', width: '240px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <Button onClick={handle?.hideAll}>Hide All</Button>
                                    <Button onClick={handle?.showAll}>Show All</Button>
                                </div>
                                {columnResponse.map(col => (
                                    <MenuItem key={col.accessorKey} sx={{ paddingLeft: 0 }}>
                                        <Switch
                                            checked={visibleColumns.includes(col.accessorKey)}
                                            onChange={() => handleColumnVisibilityChange(col.accessorKey)}
                                            disabled={col.accessorKey === "actions"}
                                        />
                                        {col.header}
                                    </MenuItem>
                                ))}
                            </div>
                        </Popover>
                        {!!filteredData?.length && <Tooltip title="Export Excel">
                            <IconButton onClick={exportToExcel} sx={{ mx: 0.5 }}>
                                <ExportExcel fill='green' />
                            </IconButton>
                        </Tooltip>}
                    </div>
                    {extraButtons}
                </div>
                <div ref={tableContainerRef} style={{ overflow: 'auto', position: 'relative', height: height }}>
                    <table className='tableStyles' style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup, columnIndex) => {
                                const groupedHeaders = {};

                                headerGroup.headers.forEach(header => {
                                    if (header.column.columnDef.groupName) {
                                        const groupName = header.column.columnDef.groupName;
                                        if (!groupedHeaders[groupName]) {
                                            groupedHeaders[groupName] = [];
                                        }
                                        groupedHeaders[groupName].push(header);
                                    }
                                });

                                return (
                                    <React.Fragment key={headerGroup.id}>
                                        {Object.entries(groupedHeaders)?.length !== 0 && (
                                            <tr style={{ display: "table-header-group", position: "sticky", top: 0, zIndex: 10 }}>
                                                {rowSelection && (
                                                    <th className='header-gruop-first-column' />
                                                )}
                                                {isGallery && <th className='header-selection-other' style={{
                                                    minWidth: "110px",
                                                    position: "sticky",
                                                    left: "29px",
                                                    zIndex: 11,
                                                }}></th>}
                                                {Object.entries(groupedHeaders).map(([group, headers], index) => (
                                                    <th key={group} colSpan={headers.length} style={{
                                                        textAlign: 'center',
                                                        padding: '10px',
                                                        borderRight: '1px solid white',
                                                        padding: '3px 5px',
                                                        color: "white",
                                                        fontSize: "14px",
                                                        backgroundColor: COLOR.primary,
                                                        transition: 'background-color 0.3s',
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 10,
                                                        position: (index === 0) ? 'sticky' : 'relative',
                                                        left: (index === 0) ? `${(index === 0 ? 139 : 0)}px` : 'auto',
                                                        zIndex: (index === 0) ? 111 : 'auto',
                                                    }}>
                                                        {group}
                                                    </th>
                                                ))}
                                            </tr>
                                        )}

                                        <tr style={{ display: "table-header-group", position: "sticky", top: 0, zIndex: 9 }}>
                                            {rowSelection && (
                                                <th className='header-selection' style={{ height: showColumnSearch ? "61px" : "26px", ...(Object.entries(groupedHeaders)?.length === 0 && { borderTop: "none" }) }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={handleSelectAll}
                                                        checked={isAllSelected}
                                                        indeterminate={isSomeSelected}
                                                    />
                                                </th>
                                            )}
                                            {isGallery && <th className='header-selection-other' style={{
                                                minWidth: "110px",
                                                position: "sticky",
                                                left: "29px",
                                                zIndex: 11,
                                            }}></th>}
                                            {headerGroup.headers.map((header, columnIndex) => {
                                                const columnWidth = header?.column?.columnDef?.size;
                                                return (
                                                    header?.column?.columnDef?.columnType === "action" ?
                                                        <th className='header-selection-other' key={header.id} style={{
                                                            width: `${columnWidth}px`,
                                                            maxWidth: `${columnWidth}px`,
                                                            minWidth: `${columnWidth}px`,
                                                            position: 'sticky',
                                                            left: header?.column?.columnDef?.hasOwnProperty("left") ? header?.column?.columnDef?.left : "29px",
                                                            zIndex: 11,
                                                        }}
                                                        >Actions</th> :
                                                        <th className='header-selection-other' key={header.id} style={{
                                                            width: `${columnWidth}px`,
                                                            maxWidth: `${columnWidth}px`,
                                                            minWidth: `${columnWidth}px`,
                                                            position: isStickyColumn(columnIndex) ? 'sticky' : 'relative',
                                                            left: isStickyColumn(columnIndex) ? `${(
                                                                columnIndex === 0 ? 139 :
                                                                    columnIndex === 1 ? 219 :
                                                                        columnIndex === 2 ? 339 :
                                                                            columnIndex === 3 ? 419 :
                                                                                columnIndex === 4 ? 499 :
                                                                                    columnIndex === 5 ? 619 :
                                                                                        columnIndex === 6 ? 669 :
                                                                                            columnIndex === 7 ? 719 :
                                                                                                columnIndex === 8 ? 799 :
                                                                                                    0)}px` : 'auto',
                                                            zIndex: isStickyColumn(columnIndex) ? 111 : 'auto',
                                                        }}
                                                        >
                                                            <div
                                                                className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'asc' ? <>&#x25b4;</> : <>&#x25be;</>) : null}
                                                            </div>
                                                            {showColumnSearch && (
                                                                <input
                                                                    type="text"
                                                                    placeholder={`${header.column.columnDef.header}`}
                                                                    value={columnSearchQueries[header.column.columnDef.accessorKey] || ''}
                                                                    onChange={(e) => {
                                                                        setColumnSearchQueries((prev) => ({
                                                                            ...prev,
                                                                            [header.column.columnDef.accessorKey]: e.target.value,
                                                                        }));
                                                                    }}
                                                                />
                                                            )}
                                                        </th>
                                                );
                                            })}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </thead>

                        {(loading) ? (
                            !!rowVirtualizer.getVirtualItems()?.length ? <tbody style={{ display: 'fixed', height: `${rowVirtualizer.getTotalSize()}px`, position: 'relative' }}>
                                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                                    const row = table.getRowModel().rows[virtualRow.index];
                                    return (
                                        <tr
                                            data-index={virtualRow.index}
                                            ref={node => rowVirtualizer.measureElement(node)}
                                            key={row.id}
                                            className={selectedDiamondIds.has(row.original[selectedId]) ? 'selected-row' : ""}
                                            style={{
                                                display: 'flex',
                                                position: 'absolute',
                                                transform: `translateY(${virtualRow.start}px)`,
                                                width: '100%',
                                                transition: 'transform 0.3s',
                                            }}
                                        >
                                            {rowSelection && (
                                                <td style={{
                                                    display: 'flex',
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    borderRight: '1px solid #ccc',
                                                    borderBottom: '1px solid #ccc',
                                                    padding: '5px 14px',
                                                    transition: 'background-color 0.3s',
                                                    overflow: "hidden",
                                                    width: "29px",
                                                    // height: "34px",
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 11,
                                                    background: "#f9f9f9"
                                                }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedDiamondIds.has(row.original[selectedId])}
                                                        onChange={() => {
                                                            const newSelectedIds = new Set(selectedDiamondIds);
                                                            if (newSelectedIds.has(row.original[selectedId])) {
                                                                newSelectedIds.delete(row.original[selectedId]);
                                                            } else {
                                                                newSelectedIds.add(row.original[selectedId]);
                                                            }
                                                            setSelectedDiamondIds(newSelectedIds);
                                                        }}
                                                    />
                                                </td>
                                            )}
                                            {isGallery && <td className='table-data' style={{
                                                minWidth: "110px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "5px",
                                                position: "sticky",
                                                left: "29px",
                                                zIndex: 11,
                                                background: "#f9f9f9"
                                            }}>
                                                <Box onClick={() => setImgVideoUrl({ url: row.original.image_url, type: 'image' })} sx={{ cursor: !row.original.image_url ? "no-drop" : "pointer", opacity: !row.original.image_url ? 0.6 : 1 }}>
                                                    <PhotoSizeSelectActualOutlined sx={{ fontSize: "14px" }} />
                                                </Box>
                                                <Box onClick={() => setImgVideoUrl({ url: row.original.video_url, type: 'video' })} sx={{ cursor: !row.original.video_url ? "no-drop" : "pointer", opacity: !row.original.video_url ? 0.6 : 1 }}>
                                                    <VideocamOutlined sx={{ fontSize: "14px" }} />
                                                </Box>
                                                <Box onClick={() => setCertificate(row.original.certi_url)} sx={{ cursor: !row.original.certi_url ? "no-drop" : "pointer", opacity: !row.original.certi_url ? 0.6 : 1 }}>
                                                    <WorkspacePremiumOutlined sx={{ fontSize: "14px" }} />
                                                </Box>
                                            </td>}
                                            {row.getVisibleCells().map((cell, index) => {
                                                const columnWidth = cell?.column?.columnDef?.size;
                                                const getBackgroundColor = cell?.column?.columnDef?.getBackgroundColor;
                                                const backgroundColor = getBackgroundColor ? getBackgroundColor(row?.original) : "";

                                                return (
                                                    cell?.column?.columnDef?.columnType === "action" ? (
                                                        <td className='table-data' key={cell.id} style={{
                                                            justifyContent: cell.column.columnDef?.textAlign,
                                                            width: `${columnWidth}px`,
                                                            minWidth: `${columnWidth}px`,
                                                            position: "sticky",
                                                            left: cell?.column?.columnDef?.hasOwnProperty("left") ? cell?.column?.columnDef?.left : "29px",
                                                            zIndex: 11
                                                        }}>
                                                            <div style={{ display: 'flex', alignItems: "left", gap: "10px" }}>
                                                                {!hideEdit && <Edit onClick={() => handleEdit(cell.row.original)} sx={{ cursor: "pointer", fontSize: "16px", color: COLOR?.primary }} />}
                                                                {!hideDelete && <Delete onClick={() => handleDelete(cell.row.original)} sx={{ cursor: "pointer", fontSize: "16px", color: "red" }} />}
                                                            </div>
                                                        </td>
                                                    ) : cell?.column?.columnDef?.columnType === "checkbox" ? (
                                                        <td className='table-data' key={cell.id} style={{
                                                            justifyContent: cell.column.columnDef?.textAlign,
                                                            width: `${columnWidth}px`,
                                                            minWidth: `${columnWidth}px`,
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={cell.getValue() || false} // Ensure checkbox is always either true or false
                                                                readOnly={!ischeckBoxEdit} // Only editable if ischeckBoxEdit is true
                                                                onClick={(e) => e.stopPropagation()} // Prevent row click event from triggering
                                                                onChange={ischeckBoxEdit ? (e) => {
                                                                    const newValue = e.target.checked;
                                                                    handleCheckboxChange(cell.row.index, cell.column.id, newValue); // Pass index and column id to update only this checkbox
                                                                } : undefined}
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className='table-data' key={cell.id} style={{
                                                            justifyContent: cell.column.columnDef?.textAlign,
                                                            width: `${columnWidth}px`,
                                                            minWidth: `${columnWidth}px`,
                                                            ...({background: backgroundColor,}),
                                                            position: isStickyColumn(index) ? 'sticky' : 'relative',
                                                            left: isStickyColumn(index) ? `${(
                                                                index === 0 ? 139 :
                                                                    index === 1 ? 219 :
                                                                        index === 2 ? 339 :
                                                                            index === 3 ? 419 :
                                                                                index === 4 ? 499 :
                                                                                    index === 5 ? 619 :
                                                                                        index === 6 ? 669 :
                                                                                            index === 7 ? 719 :
                                                                                                index === 8 ? 799 :
                                                                                                    0)}px` : 'auto',
                                                            zIndex: isStickyColumn(index) ? 111 : 'auto',
                                                        }}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    )
                                                )
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody> : <tbody><tr><td colSpan={visibleColumns.length + (rowSelection ? 1 : 0) + (isGallery ? 1 : 0)}><em className='d_block d_w-full d_text-center' style={{ marginTop: "10px" }}>No Record Found</em></td></tr></tbody>
                        ) : (
                            <Loader />
                        )}
                        {loading &&
                            rowVirtualizer.getVirtualItems()?.length !== 0 &&
                            rowCount !== 0 &&
                            tfoot && (
                                <tfoot style={{ position: 'sticky', bottom: 0, left: 0, backgroundColor: COLOR.white, zIndex: 10 }}>
                                    <tr>
                                        {rowSelection && (<td className='first-sticky-td' />)}
                                        {isGallery && (<td style={{ position: "sticky", left: "29px", zIndex: 11 }} />)}
                                        {columnResponse
                                            .filter((col) => visibleColumns.includes(col.accessorKey))
                                            .map((column, index) => {
                                                const columnWidth = column?.size || 'auto';
                                                const isNumberColumn = numberColumns.includes(column.accessorKey);
                                                return (
                                                    <td
                                                        key={column.accessorKey}
                                                        style={{
                                                            minWidth: `${columnWidth}px`,
                                                            maxWidth: `${columnWidth}px`,
                                                            width: `${columnWidth}px`,
                                                            textAlign: "right",
                                                            position: isStickyColumn(index) ? 'sticky' : 'relative',
                                                            left: isStickyColumn(index) ? `${(
                                                                index === 0 ? 139 :
                                                                    index === 1 ? 219 :
                                                                        index === 2 ? 339 :
                                                                            index === 3 ? 419 :
                                                                                index === 4 ? 499 :
                                                                                    index === 5 ? 619 :
                                                                                        index === 6 ? 669 :
                                                                                            index === 7 ? 719 :
                                                                                                index === 8 ? 799 :
                                                                                                    0)}px` : 'auto',
                                                            zIndex: isStickyColumn(index) ? 111 : 'auto',
                                                        }}
                                                    >
                                                        {memoSumRow[column.accessorKey]}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </tfoot>
                            )
                        }
                    </table>
                </div>
            </div>
            {certificate && <CertificateModal src={certificate} onClose={(() => setCertificate(""))} />}
            {imgVideoUrl && <ImgVideoModal data={imgVideoUrl} onClose={(() => setImgVideoUrl({}))} />}
        </React.Fragment>
    );
};

export default VirtualizedTableData;