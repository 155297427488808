import React, { useEffect, useRef, useState } from "react"
import { usePostData } from "../common/function/api";
import Loader from "../components/loader";
import PivotGrid, {
    Export,
    FieldChooser,
    FieldPanel,
    Scrolling,
} from "devextreme-react/pivot-grid";
import { exportPivotGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Download, Refresh, Upload } from "@mui/icons-material";
import FileUpload from "./rap-file-upload";
import { COLOR } from "../common/constant";
import ToastBar from "../components/ToastBar";

const Rap = (props) => {
    const rapViewAPI = usePostData(`/rapaport_view`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const [data, setData] = useState(null);
    const [columns, setColumn] = useState(null);
    const [toastMess, setToastMess] = useState(null);
    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "rap"
            });
            setColumn(responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRows = async () => {
        try {
            const responseData = await rapViewAPI.mutateAsync({});
            setData(responseData)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchRows();
    }, []);

    const pivotGridRef = useRef(null);

    const summaryDisplayModes = [
        { text: "None", value: "none" },
        { text: "Absolute Variation", value: "absoluteVariation" },
        { text: "Percent Variation", value: "percentVariation" },
        { text: "Percent of Column Total", value: "percentOfColumnTotal" },
        { text: "Percent of Row Total", value: "percentOfRowTotal" },
        { text: "Percent of Column Grand Total", value: "percentOfColumnGrandTotal" },
        { text: "Percent of Row Grand Total", value: "percentOfRowGrandTotal" },
        { text: "Percent of Grand Total", value: "percentOfGrandTotal" },
    ];

    const fields = columns && columns.map((col) => {

        const validSummaryDisplayMode = summaryDisplayModes.some(
            (mode) => mode.value === col.summary_type
        )
            ? col.summary_type
            : "none"; // Default to "none" if invalid or missing

        return {
            ...col,
            area: col.field_area,
            dataField: col.column_name,
            caption: col.column_header,
            area: col.field_area,
            dataType: col.column_type === "text" ? "string" : "number",
            format: col.column_format,
            width: col.column_width,
            alignment: col.column_align === 1 ? "left" : "right",
            visible: col.is_visible,
            summaryDisplayMode: validSummaryDisplayMode,
            allowSorting: true,
            allowFiltering: true,
            allowExpandAll: true,
            customizeText: (e) => {
                return col.is_text_upper ? e.valueText?.toUpperCase() : e.valueText;
            },
            summaryType: validSummaryDisplayMode?.includes("percent") ? "sum" : col.summary_type || null,
            expanded: true,
        };
    });
    const onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("PivotGrid");

        e.cancel = true; // Cancel default export to use custom implementation

        exportPivotGrid({
            component: pivotGridRef.current.instance,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "PivotGrid.xlsx"
                );
            });
        });
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/images/rap-sample.xlsx`;
        link.setAttribute('download', 'rap-sample.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const refreshMenu = () => {
        fetchRows();
    }

    return (
        <React.Fragment>
            <Box sx={{ width: "100%", display: "flex", alignItem: "center", justifyContent: "flex-end", padding: "6px 10px 6px 8px", gap: "5px" }}>
                <Tooltip title="Refresh Menu">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={refreshMenu} size="small" sx={{ color: "white" }}>
                            <Refresh sx={{ color: COLOR.primary }} />
                        </IconButton>
                    </Box>
                </Tooltip>
                <FileUpload setToastMess={setToastMess} />
                <Tooltip title="Download Sample">
                    <Button variant="contained" onClick={handleDownload} sx={{ m: 0.5, padding: "6px 10px 6px 8px" }}>
                        <Download /> Sample
                    </Button>
                </Tooltip>
            </Box>
            {data && columns ?
                <PivotGrid
                    ref={pivotGridRef}
                    dataSource={{
                        fields: fields,
                        store: data,
                    }}
                    allowSortingBySummary={true}
                    allowFiltering={true}
                    showBorders={true}
                    height="calc(97% - 50px)"
                    rowHeaderLayout="standard"
                    className="d_rap-view"
                    onExporting={onExporting}
                >
                    <Scrolling mode="virtual" />
                    <Export enabled={true} />
                    <FieldChooser enabled={true} />

                    <FieldChooser enabled={true} />
                    <FieldPanel showColumnFields={false} showDataFields={false} showFilterFields={true} allowFieldDragging={true} visible={true} />
                </PivotGrid>
                : <Loader />
            }
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    )
};

export default Rap;