import { Alert, Autocomplete, DialogContent, FormControl, FormControlLabel, Snackbar, Switch, TextField } from "@mui/material";
import { MRT_EditActionButtons } from "material-react-table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { countryStateCity } from "../action";
import { usePostData } from "../common/function/api";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";

const AddAddressModal = React.memo(({ row, table, type, setAddressFormData, addressFormData, addAddress, addressError }) => {
    const dispatch = useDispatch();
    const masterFillAPI = usePostData(`/master_fill`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const [addressData, setAddressData] = useState([]);
    const [countryStateData, setCountryStateData] = useState({
        country: [],
        state: [],
        city: []
    });
    const [toastMess, setToastMess] = useState(""); // State for Snackbar

    const fetchStateFromCountry = async (country_id) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_name": "state_from_country",
                "mast_type": "select",
                "country_id": country_id
            });

            if (responseData) {
                setCountryStateData(prev => ({
                    ...prev,
                    state: responseData,
                    city: [] // Reset city if country changes
                }));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchCityFromStateCountry = async (state_id, country_id) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_name": "city_from_state_country",
                "mast_type": "select",
                "country_id": country_id,
                "state_id": state_id,
            });

            if (responseData) {
                setCountryStateData(prev => ({
                    ...prev,
                    city: responseData
                }));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddressChange = (event, columnId, columnType) => {
        if (columnType === "select") {
            if (columnId === "country_name" && event.target.value) {
                fetchStateFromCountry(event.target.value);
                setAddressFormData({ ...addressFormData, state_name: '', city_name: '' }); // Clear state and city
            } else if (columnId === "state_name" && event.target.value && addressFormData?.country_name) {
                fetchCityFromStateCountry(event.target.value, addressFormData?.country_name);
                setAddressFormData({ ...addressFormData, city_name: '' }); // Clear city
            }
            setAddressFormData({ ...addressFormData, [columnId]: event.target.value });
        } else if (columnType === "switch") {
            setAddressFormData({ ...addressFormData, [columnId]: event.target.checked });
        } else {
            setAddressFormData({ ...addressFormData, [columnId]: event.target.value });
        }
    };

    useEffect(() => {
        const fetchAddressColumn = async () => {
            try {
                const responseData = await viewColumnsFillAPI.mutateAsync({
                    "type_id": 0,
                    "form_name": "branch_address"
                });

                if (responseData) {
                    // Sort columns by form_display_ord and set the state
                    setAddressData(responseData.sort((a, b) => a.form_display_ord - b.form_display_ord));
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const fetchCountry = async () => {
            try {
                const responseData = await masterFillAPI.mutateAsync({
                    "mast_name": "country",
                    "mast_type": "select"
                });

                if (responseData) {
                    setCountryStateData(prev => ({
                        ...prev,
                        country: responseData
                    }));

                    if (addressFormData?.state_name || addressFormData?.city_name) {
                        const { state_name, city_name, ...rest } = addressFormData;
                        setAddressFormData(rest); // Reset state and city fields if they exist
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (addAddress) {
            fetchAddressColumn();
            fetchCountry();
        }
    }, [addAddress]);

    useEffect(() => {
        if ((addAddress && type === "edit") && addressFormData?.state_id && addressFormData?.country_name) {
            fetchStateFromCountry(addressFormData.country_name);
            fetchCityFromStateCountry(addressFormData.state_id, addressFormData.country_name);
        }
    }, [addressFormData?.state_id, addressFormData?.country_name, type]);

    useEffect(() => {
        // Dispatch the action to update the store
        dispatch(countryStateCity(countryStateData));
    }, [dispatch, countryStateData]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMess("");
    };

    return (
        <React.Fragment>
            <DModal
                open={type}
                onClose={() => table.setCreatingRow(null)}
                DialogTitle={type !== "edit" ? 'Add Address' : "Edit Address"}
                DialogContent={
                    <DialogContent className="d_row" sx={{ padding: "24px 24px 0 24px !important" }}>
                        {addressData?.filter(_ => _?.is_visible_form).map((column, index) => {
                            const { column_name, column_header, column_type, is_required, css_class_name } = column;
                            const value = addressFormData[column_name] || '';
                            const selectOption = column_name === "country_name" ? countryStateData?.country : column_name === "state_name" ? countryStateData?.state : column_name === "city_name" ? countryStateData?.city : [];
                            const isFirstField = index === 0;
                            if (!column.is_visible) return null;

                            switch (column_type) {
                                case 'text':
                                    return (
                                        <div className={css_class_name} key={index}>
                                            <DTextField
                                                label={column_header}
                                                value={value}
                                                autoFocus={isFirstField}
                                                required={is_required}
                                                onChange={(e) => handleAddressChange(e, column_name, column_type)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            />
                                        </div>
                                    );
                                case 'textarea':
                                    return (
                                        <div className={css_class_name} key={index}>
                                            <DTextField
                                                label={column_header}
                                                value={value}
                                                required={is_required}
                                                autoFocus={isFirstField}
                                                onChange={(e) => handleAddressChange(e, column_name, column_type)}
                                                variant="outlined"
                                                fullWidth
                                                size="large"
                                                multiline
                                                rows={2}
                                            />
                                        </div>
                                    );
                                case 'select':
                                    return (
                                        <div className={css_class_name} key={index}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    value={selectOption.find(option => option?.value === value) || null}
                                                    onChange={(event, newValue) => handleAddressChange({ target: { value: newValue?.value } }, column_name, column_type)}
                                                    options={selectOption || []}
                                                    getOptionLabel={(option) => { return option.label ? option.label : "Unknown Label"; }}
                                                    disabled={column_name === "state_name" && !addressFormData?.country_name || column_name === "city_name" && !addressFormData?.state_name}
                                                    size="small"
                                                    autoFocus={isFirstField}
                                                    renderOption={(props, option) => {
                                                        const { id, key, ...otherProps } = props;
                                                        return (
                                                            <li key={id} {...otherProps}>
                                                                {option.label}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required={is_required}
                                                            {...params}
                                                            label={column_header}
                                                            fullWidth
                                                        />
                                                    )}
                                                    filterOptions={(options, state) => {
                                                        const inputValue = state.inputValue.toLowerCase();
                                                        return options.filter(option => option?.label?.toLowerCase().includes(inputValue));
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option?.value === value}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                case 'switch':
                                    return (
                                        <div className="d_col-2" style={{ marginTop: "10px" }} key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                    autoFocus={isFirstField}
                                                        checked={value || false}
                                                        onChange={(e) => handleAddressChange(e, column_name, column_type)}
                                                    />
                                                }
                                                label={column_header}
                                            />
                                        </div>
                                    );
                                default:
                                    return null;
                            }
                        })}

                        {addressError && <em style={{ color: "red" }}>{addressError}</em>}
                    </DialogContent>
                }
                DialogActions={<MRT_EditActionButtons variant="text" table={table} row={row} />}
            />
            <Snackbar
                open={toastMess} onClose={handleClose} autoHideDuration={3000} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {toastMess || ""}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
});

export default AddAddressModal;
