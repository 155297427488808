import DataGrid, {
    Column,
    Editing,
    Form,
    Lookup,
    Paging,
    Popup,
    RequiredRule,
    Scrolling,
} from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';

const EditableDataGrid = ({
    data,
    height,
    columns,
    onRowUpdated,
    onRowInserted,
    onEditingStart,
    onRowDeleted,
    allowAdding = false,
    allowUpdating = false,
    allowDeleting = false,
    dropdownOptions = {},
    className
}) => {
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        if (data) setGridData(data);
    }, [data]);

    const handleRowUpdated = (e) => {
        setGridData((prevData) => {
            const updatedData = [...prevData];
            const index = updatedData.findIndex((item) => item.id === e.key);
            if (index > -1) {
                updatedData[index] = { ...updatedData[index], ...e.data };
            }
            return updatedData;
        });
        if (onRowUpdated) onRowUpdated(e);
    };

    const handleRowInserting = (e) => {
        const newRow = { id: gridData.length + 1, ...e.data };
        setGridData((prevData) => [...prevData, newRow]);
        if (onRowInserted) onRowInserted(e);
    };

    const handleRowRemoved = (e) => {
        setGridData((prevData) => prevData.filter((item) => item.id !== e.key));
        if (onRowDeleted) onRowDeleted(e.key); // Call the delete callback
    };

    const onToolbarPreparing = (e) => {
        if (allowAdding) {
            e.toolbarOptions.items = e.toolbarOptions.items.filter((item) => item.name !== 'addRowButton');

            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Add New',
                    onClick: () => {
                        e.component.addRow();
                    },
                },
            });
        }
    };

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
            <DataGrid
                dataSource={gridData}
                keyField="id"
                showRowLines={true}
                showBorders={true}
                onEditingStart={onEditingStart}
                onRowUpdated={handleRowUpdated}
                onRowInserting={handleRowInserting}
                onRowRemoved={handleRowRemoved}
                height={height}
                className={className}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Editing mode="batch" allowUpdating={allowUpdating} allowAdding={allowAdding} allowDeleting={allowDeleting} useIcons={true}>
                    <Popup />
                    <Form>
                        <RequiredRule message="This field is required" />
                    </Form>
                </Editing>
                <Scrolling mode="virtual" />
                <Paging enabled={false} />

                {columns.map((col, index) => {
                    const lookupData = dropdownOptions[col.accessorKey] || null;
                    return (
                        <Column key={index} dataField={col.accessorKey} caption={col.header} visible={col.is_visible} alignment={col.textAlign} {...col}>
                            {lookupData && (
                                <Lookup dataSource={lookupData} valueExpr="id" displayExpr="label" />
                            )}
                        </Column>
                    );
                })}
            </DataGrid>
        </div>
    );
};

export default EditableDataGrid;
