import { Upload, UploadFile } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import { APIURL } from '../common/constant';
import { usePostData } from '../common/function/api';
import { getLocalStorage } from '../common/function/common';
import ToastBar from '../components/ToastBar';
import ConfirmationModal from '../components/confirmationModal';

function FileUpload({ setToastMess }) {
  const userData = getLocalStorage("userAuth"); // Get user data from local storage
  const token = userData?.token; // Extract token from user data
  const fileInputRef = useRef(null);

  const [file, setFile] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
      setOpenConfirmation(true); // Open confirmation dialog
    } else {
      alert('Please upload a valid Excel file.');
    }
  };

  // Confirm and proceed to upload
  const handleConfirmUpload = async () => {
    setOpenConfirmation(false);
    if (!file) {
      console.log('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${APIURL}/rapaport_save`, {
        'method': 'POST',
        'headers': {
          'Authorization': `Bearer ${token}`,  // Include token in Authorization header
          ...(formData ? {} : { 'Content-Type': 'application/json' }), // Don't set Content-Type for FormData
        },
        'body': formData,
      });
      if (response?.status === 200) {
        setToastMess("File uploaded successfully");
        handleCancelUpload();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file.');
    }
  };

  // Cancel file selection
  const handleCancelUpload = () => {
    setOpenConfirmation(false);
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input
    }
  };

  return (
    <div>
      <Tooltip title="Upload Excel File">
        <Button
          variant="contained"
          component="label"
          sx={{ m: 0.5, padding: '6px 10px 6px 8px', textTransform: 'capitalize' }}
        >
          <Upload /> Upload
          <input
            type="file"
            accept=".xlsx, .xls"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Button>
      </Tooltip>

      {/* Confirmation Dialog */}
      <ConfirmationModal
        open={openConfirmation}
        onClose={handleCancelUpload}
        header={"Confirm Upload"}
        title={
          <>Are you sure you want to upload the selected file: <strong>{file?.name}</strong>?</>
        }
        action={handleConfirmUpload}
        variant="aliceblue"
        icon={<Upload sx={{ height: "36px", width: "36px" }} color="primary" />}
      />
    </div>
  );
}

export default FileUpload;
