import PivotGrid, {
    Export,
    FieldChooser,
    FieldPanel,
    Scrolling,
} from "devextreme-react/pivot-grid";
import { exportPivotGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import React, { useRef } from "react";

const ReactPivotGrid = ({ columns, data, className }) => {
    const pivotGridRef = useRef(null);
    const calculatedFieldConfig = {
        rap: {
            caption: "Rap",
            summaryType: "custom",
            calculateCustomSummary: (options) => {
                if (options.summaryProcess === "start") {
                    options.totalValue = {
                        rapSum: 0,
                        caratSum: 0,
                    };
                }

                if (options.summaryProcess === "calculate") {
                    const { rap_amount, carat } = options.value || {};
                    if (rap_amount && carat) {
                        options.totalValue.rapSum += rap_amount;
                        options.totalValue.caratSum += carat;
                    }
                }

                if (options.summaryProcess === "finalize") {
                    const { rapSum, caratSum } = options.totalValue;
                    if (caratSum !== 0) {
                        options.totalValue = (rapSum / caratSum).toFixed(2);
                    } else {
                        options.totalValue = 0;
                    }
                }
            },
        },
        disc: {
            caption: "Disc",
            summaryType: "custom",
            calculateCustomSummary: (options) => {
                if (options.summaryProcess === "start") {
                    options.totalValue = {
                        rateSum: 0,
                        rapSum: 0,
                    };
                }

                if (options.summaryProcess === "calculate") {
                    const { rate_amount, rap_amount } = options.value || {};
                    if (rate_amount && rap_amount) {
                        options.totalValue.rateSum += rate_amount;
                        options.totalValue.rapSum += rap_amount;
                    }
                }

                if (options.summaryProcess === "finalize") {
                    const { rateSum, rapSum } = options.totalValue;
                    if (rapSum !== 0) {
                        options.totalValue = ((rateSum / rapSum) * 100 - 100).toFixed(4); // Disc formula
                    } else {
                        options.totalValue = 0;
                    }
                }
            },
        },
        prdays: {
            caption: "PR Days",
            summaryType: "custom",
            calculateCustomSummary: (options) => {
                if (options.summaryProcess === "start") {
                    options.totalValue = {
                        prdaysSum: 0,
                        prRateSum: 0,
                    };
                }

                if (options.summaryProcess === "calculate") {
                    const { prdays_amount, rate_amount } = options.value || {};
                    const prdaysAmount = parseFloat(prdays_amount) || 0;
                    const rateAmount = parseFloat(rate_amount) || 0;

                    options.totalValue.prdaysSum += prdaysAmount;
                    options.totalValue.prRateSum += rateAmount;
                }

                if (options.summaryProcess === "finalize") {
                    const { prdaysSum, prRateSum } = options.totalValue;

                    if (prRateSum !== 0) {
                        options.totalValue = (prdaysSum / prRateSum)?.toFixed(2);
                    } else {
                        options.totalValue = "0.00";
                    }
                }
            },
        },
        rate: {
            caption: "Rate",
            summaryType: "custom",
            calculateCustomSummary: (options) => {
                if (options.summaryProcess === "start") {
                    options.totalValue = {
                        rateSum: 0,
                        caratSum: 0,
                    };
                }

                if (options.summaryProcess === "calculate") {
                    const { rate_amount, carat } = options.value || {};

                    const rateAmount = parseFloat(rate_amount) || 0;
                    const caratAmount = parseFloat(carat) || 0;

                    options.totalValue.rateSum += rateAmount;
                    options.totalValue.caratSum += caratAmount;
                }

                if (options.summaryProcess === "finalize") {
                    const { rateSum, caratSum } = options.totalValue;

                    if (caratSum !== 0) {
                        options.totalValue = (rateSum / caratSum).toFixed(2);
                    } else {
                        options.totalValue = "0.00";
                    }
                }
            },
        }
    };

    const summaryDisplayModes = [
        { text: "None", value: "none" },
        { text: "Absolute Variation", value: "absoluteVariation" },
        { text: "Percent Variation", value: "percentVariation" },
        { text: "Percent of Column Total", value: "percentOfColumnTotal" },
        { text: "Percent of Row Total", value: "percentOfRowTotal" },
        { text: "Percent of Column Grand Total", value: "percentOfColumnGrandTotal" },
        { text: "Percent of Row Grand Total", value: "percentOfRowGrandTotal" },
        { text: "Percent of Grand Total", value: "percentOfGrandTotal" },
    ];

    const fields = columns.map((col) => {
        const calculatedField = calculatedFieldConfig[col.column_name];
        if (calculatedField) {
            return {
                ...col,
                ...calculatedField,
                area: col.field_area,
            };
        }

        const validSummaryDisplayMode = summaryDisplayModes.some(
            (mode) => mode.value === col.summary_type
        )
            ? col.summary_type
            : "none"; // Default to "none" if invalid or missing

        return {
            dataField: col.column_name,
            caption: col.column_header,
            area: col.field_area,
            dataType: col.column_type === "text" ? "string" : "number",
            format: col.column_format,
            width: col.column_width,
            alignment: col.column_align === 1 ? "left" : "right",
            visible: col.is_visible,
            summaryDisplayMode: validSummaryDisplayMode,
            allowSorting: true,
            allowFiltering: true,
            allowExpandAll: true,
            customizeText: (e) => {
                if (col.column_name === "carat") return e.value.toFixed(3);
                if (["prdays_amount", "rap_amount", "rate_amount"]?.includes(col.column_name)) return e.value.toFixed(2);

                if (validSummaryDisplayMode?.includes("percent")) {
                    return `${(e.value * 100)?.toFixed(2)}%`; // Show raw percentage as received
                }

                return col.is_text_upper ? e.valueText?.toUpperCase() : e.valueText;
            },
            summaryType: validSummaryDisplayMode?.includes("percent") ? "sum" : col.summary_type || null,
            expanded: true,
        };
    });
    const onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("PivotGrid");

        e.cancel = true; // Cancel default export to use custom implementation

        exportPivotGrid({
            component: pivotGridRef.current.instance,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "PivotGrid.xlsx"
                );
            });
        });
    };

    return (
        <PivotGrid
            ref={pivotGridRef}
            dataSource={{
                fields: fields,
                store: data,
            }}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            rowHeaderLayout="standard"
            className={className}
            onExporting={onExporting}
        >
            <Scrolling mode="virtual" />
            <Export enabled={true} />
            <FieldChooser enabled={true} />

            <FieldChooser enabled={true} />
            <FieldPanel showColumnFields={false} showDataFields={false} showFilterFields={true} allowFieldDragging={true} visible={true} />
        </PivotGrid>
    );
};

export default ReactPivotGrid;
