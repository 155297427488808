import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import { getLabels, getValues } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import Loader from "../components/loader";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import PartyModal from "./party-modal";
import { Base64 } from "js-base64";

const Party = React.memo(({ type_id, form_name }) => {
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const masterViewAPI = usePostData(`/master_view`);
  const saveAPI = usePostData(`/party_save`);
  const deleteAPI = usePostData(`/party_delete`);
  const [columnResponse, setColumnResponse] = useState(null);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  const [openModal, setOpenModal] = useState({ flag: false, data: null });
  const [newKey, setNewKey] = useState(0);

  const createFunction = async ({ row, values, table }) => {
    const requestData = {
      party_data: {
        ...formData,
        ...(formData?.party_code && { party_code: formData?.party_code }),
        party_type_id: getValues(formData?.party_type_name),
        group_party_code: getValues(formData?.group_party_name),
        sale_person_id: getValues(formData?.sale_person_name),
        classification_id: getValues(formData?.classification_name),
        business_type_id: formData?.business_type_name?.value,
        isd_code_id: formData?.contact_no?.value,
        contact_no: formData?.contact_no?.number,
        isd_code1_id: formData?.contact_no1?.value,
        isd_code2_id: formData?.contact_no2?.value,
        contact_no1: formData?.contact_no1?.number,
        contact_no2: formData?.contact_no2?.number,
        party_rating_id: formData?.party_rating_name?.value,
        member_type_id: formData?.member_type_name?.value
      },
    };

    try {
      const responseData = await saveAPI.mutateAsync(requestData);

      if (responseData?.success) {
        if (formData?.party_code) {
          setRows((prevData) =>
            prevData.map((item) =>
              item.party_code === formData?.party_code
                ? {
                  ...requestData?.party_data,
                  party_code: requestData?.party_data?.party_code,
                  business_type_name: requestData?.party_data?.business_type_name?.label,
                  member_type_name: requestData?.party_data?.member_type_name?.label,
                  isd_code1_name: requestData?.party_data?.isd_code1_name,
                  isd_code2_name: requestData?.party_data?.isd_code2_name,
                  contact_no1: requestData?.party_data?.contact_no1,
                  contact_no2: requestData?.party_data?.contact_no2,
                  party_rating_name: requestData?.party_data?.party_rating_name?.label,
                  party_type_name: getLabels(requestData?.party_data?.party_type_name),
                  group_party_name: getLabels(requestData?.party_data?.group_party_name),
                  ...(requestData?.party_data?.sale_person_name && { sale_person_name: getLabels(requestData?.party_data?.sale_person_name) }),
                  ...(requestData?.party_data?.classification_name && { classification_name: getLabels(requestData?.party_data?.classification_name) }),
                  is_active: requestData?.party_data?.is_active
                }
                : item
            )
          );
        } else {
          setRows((prevData) => [...prevData, {
            ...requestData?.party_data,
            party_code: responseData?.party_code,
            business_type_name: requestData?.party_data?.business_type_name?.label,
            member_type_name: requestData?.party_data?.member_type_name?.label,
            isd_code1_name: formData?.contact_no1?.label.split(":")[0].trim(),
            isd_code2_name: formData?.contact_no2?.label.split(":")[0].trim(),
            isd_code_name: formData?.contact_no?.label.split(":")[0].trim(),
            contact_no: formData?.contact_no?.number,
            contact_no1: formData?.contact_no1?.number,
            contact_no2: formData?.contact_no2?.number,
            party_rating_name: requestData?.party_data?.party_rating_name?.label,
            party_type_name: getLabels(formData?.party_type_name),
            group_party_name: getLabels(formData?.group_party_name),
            ...(formData?.sale_person_name && { sale_person_name: getLabels(formData?.sale_person_name) }),
            ...(formData?.classification_name && { classification_name: getLabels(formData?.classification_name) }),
            is_active: requestData?.party_data?.is_active
          }]);
        }
        handleClose()
        setToastMess(responseData?.message);
      } else {
        setError(responseData?.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteConfirmation = async (id) => {
    try {
      const responseData = await deleteAPI.mutateAsync({
        party_code: id,
      });

      if (responseData?.success) {
        setRows((prevData) => prevData.filter((item) => item.party_code !== id));
        setOpenDeletemodal({ flag: false, data: null });
        setToastMess(responseData.message); // Assuming responseData contains a message
      } else {
        setOpenDeletemodal({ flag: false, data: null });
        setToastMess(responseData.message); // Assuming responseData contains a message
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleChange = (event, columnId, columnType) => {
    if (columnType === "select") {
      setFormData({ ...formData, [columnId]: event.target.value });
    } else if (columnType === "switch") {
      if (columnId === "is_party_name_as_per_member_name") {
        if (event.target.checked) {
          setFormData({ ...formData, [columnId]: event.target.checked, party_name: `${formData.first_name} ${formData.middle_name} ${formData.last_name}` });
        } else {
          setFormData({ ...formData, [columnId]: event.target.checked, party_name: `` });
        }
      } else setFormData({ ...formData, [columnId]: event.target.checked });
    } else if (columnType === "contact") {
      setFormData({ ...formData, [columnId]: { ...formData[columnId], number: event.target.value } });
    } else if (columnId === "party_type_name") {
      setFormData({ ...formData, [columnId]: event.target.value, is_party_name_as_per_member_name: false });
    } else if (columnType === "text") {
      if (formData?.is_party_name_as_per_member_name) {
        setFormData({ ...formData, [columnId]: event.target.value, party_name: `${formData.first_name} ${formData.middle_name} ${formData.last_name}` })
      } else setFormData({ ...formData, [columnId]: event.target.value });
    } else {
      setFormData({ ...formData, [columnId]: event.target.value });
    }
  };

  const fetchPartyColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: type_id,
        form_name: form_name,
        view_name: form_name,
      });

      if (responseData) {
        const columnData = responseData
          ?.filter(col => col?.is_visible_form)
          .map((col) => ({
            accessorKey: col.column_name,
            header: col.column_header,
            size: col.column_width,
            filterVariant: 'autocomplete',
            flex: 1,
            filterFn: 'includesString',
            is_visible: col?.is_visible,
            is_required: col?.is_required,
            css_class_name: col?.css_class_name,
            column_type: col?.column_type,
            muiTableBodyCellProps: ({ cell }) => ({
              // Custom rendering logic for "switch" column types
              children: (["is_party_name_as_per_member_name", "is_active"]?.includes(col.column_name)) ? (
                <label className="label toggle">
                  <input type="checkbox" readOnly checked={cell.getValue()} className="toggle_input" />
                  <div className="toggle-control"></div>
                </label>
              ) : (
                cell.getValue()
              ),
            }),
          }));
        const actionsColumn = {
          accessorKey: 'actions',
          header: 'Actions',
          filterVariant: 'autocomplete',
          flex: 1,
          filterFn: 'includesString',
          size: 100, // Adjust size if needed
          textAlign: 'center',
          columnType: 'action', // Custom type
          is_visible: true,
          left: 0
        };
        columnData.splice(0, 0, actionsColumn);

        setColumnResponse(columnData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchPartyData = async () => {
    try {
      const responseData = await masterViewAPI.mutateAsync({
        view_name: form_name
      });

      if (responseData) {
        const rowsData = responseData.map((row, index) => ({
          id: index + 1,
          ...row,
          is_active: row?.is_active,
        }));
        setRows(rowsData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchPartyColumn();
    fetchPartyData();
  }, [type_id, form_name]);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey) {
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const checkLength = (value) => {
    // Check if the value is a non-null string before splitting
    if (typeof value === 'string') {
      const valuesArray = value.split(',');
      return valuesArray.length;
    }
    return 0;
  };

  useEffect(() => {
    if (editingRow) {
      const preparedData = {
        ...editingRow,
        party_code: editingRow?.party_code,
        business_type_name: { label: editingRow?.business_type_name, value: editingRow?.business_type_id },
        contact_no: { label: editingRow?.isd_code_name, value: editingRow?.isd_code_id, number: editingRow?.contact_no },
        member_type_name: { label: editingRow?.member_type_name, value: editingRow?.member_type_id },
        contact_no1: { label: editingRow?.isd_code1_name, value: editingRow?.isd_code1_id, number: editingRow?.contact_no1 },
        contact_no2: { label: editingRow?.isd_code2_name, value: editingRow?.isd_code2_id, number: editingRow?.contact_no1 },
        party_rating_name: { label: editingRow?.party_rating_name, value: editingRow?.party_rating_id },

        // Check for party_type_id (make sure it's a string before splitting)
        party_type_name: checkLength(editingRow?.party_type_id) === 1
          ? [{ label: editingRow?.party_type_name, value: Number(editingRow?.party_type_id) }]
          : (typeof editingRow?.party_type_id === 'string' ? editingRow.party_type_id.split(",") : [])
            .map((id, index) => ({
              value: Number(id),
              label: typeof editingRow?.party_type_name === 'string' ? editingRow.party_type_name.split(",")[index] : ""
            })) ?? [],

        // Same logic for group_party_name
        group_party_name: checkLength(editingRow?.group_party_code) === 1
          ? [{ label: editingRow?.group_party_name, value: Number(editingRow?.group_party_code) }]
          : (typeof editingRow?.group_party_code === 'string' ? editingRow.group_party_code.split(",") : [])
            .map((id, index) => ({
              value: Number(id),
              label: typeof editingRow?.group_party_name === 'string' ? editingRow.group_party_name.split(",")[index] : ""
            })) ?? [],

        // Same logic for sale_person_name
        sale_person_name: checkLength(editingRow?.sale_person_id) === 1
          ? [{ label: editingRow?.sale_person_name, value: Number(editingRow?.sale_person_id) }]
          : (typeof editingRow?.sale_person_id === 'string' ? editingRow.sale_person_id.split(",") : [])
            .map((id, index) => ({
              value: Number(id),
              label: typeof editingRow?.sale_person_name === 'string' ? editingRow.sale_person_name.split(",")[index] : ""
            })) ?? [],

        // Same logic for classification_name
        classification_name: checkLength(editingRow?.classification_id) === 1
          ? [{ label: editingRow?.classification_name, value: Number(editingRow?.classification_id) }]
          : (typeof editingRow?.classification_id === 'string' ? editingRow.classification_id.split(",") : [])
            .map((id, index) => ({
              value: Number(id),
              label: typeof editingRow?.classification_name === 'string' ? editingRow.classification_name.split(",")[index] : ""
            })) ?? [],

        is_active: editingRow?.is_active
      }

      setFormData(preparedData);
    }
  }, [editingRow]);

  const handleEdit = (data) => {
    const params = {
      party_code: data?.party_code,
      type_id: type_id,
      form_name: form_name,
    };

    const encodedParams = Base64.encode(JSON.stringify(params));
    const url = `/party/details?data=${encodeURIComponent(encodedParams)}`;

    window.open(url, '_blank');
  }

  const handleClose = () => {
    setOpenModal({ flag: false, data: null })
    setFormData({});
    setEditingRow(null)
    setError("");
  }

  return (
    <React.Fragment>
      <div style={{ height: "100%", width: "100%" }}>
        {(masterViewAPI?.isSuccess) ?
          <VirtualizedTableData
            height={"820px"}
            data={rows} visibleColumn={visibleColumn}
            handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
            loading={!viewColumnsFillAPI?.isPending}
            columnResponse={columnResponse}
            rowCount={0}
            handleEdit={(data) => handleEdit(data)}
            uploadExcel={<Box><Button onClick={(e) => setOpenModal({ flag: true, data: "create" })} variant="contained" startIcon={<Add />}>Add</Button></Box>}
          />
          :
          <Loader />}
      </div>

      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Party?"}
        title={
          <>Are you sure you want to delete <strong>"{openDeletemodal?.data?.party_name}"</strong> party?</>
        }
        action={() => deleteConfirmation(openDeletemodal?.data?.party_code)}
      />
      {openModal?.flag && <PartyModal
        type={openModal?.data}
        onClose={handleClose}
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        error={error}
        newKey={newKey}
        columnResponse={columnResponse}
        setNewKey={setNewKey}
        createFunction={createFunction}
        addressModal
      />}
      {toastMess && <ToastBar open={toastMess} onClose={setToastMess} />}
    </React.Fragment>
  );
});

export default Party;