import { Clear, Merge, Search, Splitscreen } from "@mui/icons-material";
import { Box, Button, FormControl } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import { getLocalStorage, getValues } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import VendorMemoView from "./vendor-memo-view";

const VendorMemo = React.memo(() => {
  const boxRef = useRef(null);
  const masterFillAPI = usePostData(`/master_fill`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const memoViewAPI = usePostData(`/other_company_memo_view`);
  const deleteAPI = usePostData(`/other_company_memo_delete`);
  const userData = getLocalStorage("userAuth");
  const [selectData, setSelectData] = useState({
    memo_type_name: [],
    party_name: [],
    broker_name: []
  });
  const [boxHeight, setBoxHeight] = useState(0);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [type, setType] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toastMess, setToastMess] = useState(null);
  const [openMemoForm, setOpenMemoForm] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  const [selectedMemoIds, setSelectedMemoIds] = useState([]);

  useEffect(() => {
    // Get the height of the div after it is rendered
    if (boxRef.current) {
      const height = boxRef.current.clientHeight;
      setBoxHeight(height + 85);
    }
  }, []);
  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };

  const fetchAllData = async () => {
    try {
      const [memoTypeName, partyName, brokerName, memoReasonName] = await Promise.all([
        fetchData("select", "memo_type", "memo_type_name"),
        fetchData("select", "party", "party_name"),
        fetchData("select", "broker", "broker_name"),
      ]);

      setSelectData({
        ...memoTypeName, ...partyName, ...brokerName, ...memoReasonName
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRows = async (resetFields = false) => {
    setLoading(true);

    if (resetFields) {
      // Clear all fields in formData
      setFormData({
        memo_type_name: null,
        party_name: null,
        broker_name: null,
      });
    }

    try {
      const responseData = await memoViewAPI.mutateAsync({
        "trans_name": "memo_summary",
        "memo_no": 0,
        ...(resetFields ? {} : {
          ...(formData?.memo_type_name && { memo_type_id: getValues(formData?.memo_type_name) }),
          ...(formData?.party_name && { party_code: getValues(formData?.party_name) }),
          ...(formData?.broker_name && { broker_code: getValues(formData?.broker_name) })
        }),
      });

      const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];

      setRows(rowsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: 0,
        form_name: "memo",
        view_name: "memo_summary"
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true
      };
      columns.splice(1, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchColumn();
    fetchAllData();
    fetchRows();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleChange = (event, name) => {
    setFormData({ ...formData, [name]: event.target.value })
  };

  const handleDelete = async (id) => {
    setLoader(true);
    deleteAPI.mutate({
      memo_no: id,
      user_id: userData?.user_id
    }, {
      onSuccess: (responseData) => {
        if (responseData) {
          setLoader(false);
          setRows((prevData) => prevData.filter((item) => item.memo_no !== id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
        } else {
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleEdit = (data) => {
    setOpenMemoForm(data)
  }

  const handle = {
    selectedRowsChange: (memoId) => {
      setSelectedMemoIds(memoId);
    },
    mergeSplit: (type) => {
      setType(type)
      setOpenMemoForm(selectedMemoIds?.join(","))
    }
  }

  const handleSelectionChange = (newValue, name) => {
    const selectedValues = newValue.map(option => option);
    handleChange({ target: { value: selectedValues } }, name);
  };

  const sumRow = {};
  let selectedRows = rows;
  const rowsToSum = selectedRows?.length ? selectedRows : rows;
  const numberColumns = ['carat', 'pcs']

  const memoPcs = rowsToSum.reduce((acc, row) => acc + Number(row['pcs'] || 0), 0);
  const memoCaratSum = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0);
  sumRow['carat'] = memoCaratSum.toFixed(2);
  sumRow['pcs'] = memoPcs.toFixed(2);

  return (
    <React.Fragment>
      <Box ref={boxRef} sx={{ display: "flex", justifyContent: "space-between", padding: "15px 15px 0 15px" }}>
        <div className="d_row" style={{ width: "100%" }}>
          <div className="d_col-2">
            <FormControl fullWidth>
              <MultipleSelectCheckmarks
                options={selectData?.party_name || []}
                value={formData?.party_name || []}
                handleChange={(e) => handleSelectionChange(e, "party_name")}
                headerName={"Party"}
              />
            </FormControl>
          </div>
          <div className="d_col-2">
            <FormControl fullWidth>
              <MultipleSelectCheckmarks
                options={selectData?.broker_name || []}
                value={formData?.broker_name || []}
                handleChange={(e) => handleSelectionChange(e, "broker_name")}
                headerName={"Broker"}
              />
            </FormControl>
          </div>
          <div className="d_col-2" style={{ margin: "0" }}>
            <FormControl fullWidth>
              <MultipleSelectCheckmarks
                options={selectData?.memo_type_name || []}
                value={formData?.memo_type_name || []}
                handleChange={(e) => handleSelectionChange(e, "memo_type_name")}
                headerName={"Memo Type"}
              />
            </FormControl>
          </div>
        </div>
        <Box>
          <Box display="flex" gap={1} marginLeft={2} marginBottom="10px">
            {selectedMemoIds?.length > 1 && <Button variant="contained" onClick={() => handle.mergeSplit("merge")} startIcon={<Merge />}>Merge</Button>}
            {selectedMemoIds?.length === 1 && <Button variant="contained" onClick={() => handle.mergeSplit("split")} startIcon={<Splitscreen />}>Split</Button>}
            <Button variant="contained" onClick={() => fetchRows()} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
            <Button variant="contained" onClick={() => fetchRows(true)} startIcon={<Clear />}>Clear</Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <div style={{ height: `calc(100vh - ${boxHeight}px)`, width: "100%" }}>
          {(columnResponse && visibleColumn !== null) &&
            <VirtualizedTableData
              style={{ margin: 0 }}
              height={`calc(100vh - ${boxHeight}px)`}
              data={rows} visibleColumn={visibleColumn}
              loading={!loading && !viewColumnsFillAPI?.isPending}
              columnResponse={columnResponse}
              handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data?.memo_no })}
              handleEdit={(data) => handleEdit(data)} rowSelection
              onSelectedRowsChange={handle?.selectedRowsChange}
              numberColumns={numberColumns}
              memoSumRow={sumRow}
              totalCountColumn={'memo_type_name'}
              selectedId="memo_no"
            />}
        </div>
      </Box>
      <VendorMemoView open={openMemoForm} onClose={setOpenMemoForm} setMemoRows={setRows} type={type} setType={setType} withExcel />
      <ToastBar open={toastMess} onClose={setToastMess} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Vendor Memo?"}
        title={
          <>Are you sure you want to delete this vendor memo?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
        loader={loader}
      />
    </React.Fragment>
  );
});

export default VendorMemo;
