import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { Suspense, lazy } from "react";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { COLOR } from "./app-dt-sell/common/constant";
import Loader from "./app-dt-sell/components/loader";
import RoutesConfig from "./app-dt-sell/RoutesConfig";
import store from './app-dt-sell/store';
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import './App.css';

const Layout = lazy(() => import("./app-dt-sell/auth/layout"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.primary
    }
  }
});

function App() {

  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {/* <Layout> */}
                <RoutesConfig />
              {/* </Layout> */}
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
