import { Popper, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import * as React from 'react';

const MultipleSelectCheckmarks = ({
  options,
  value,
  handleChange,
  headerName,
  required,
  autoFocus = false,
  limitTags = 2
}) => {
  const selectAllOption = { label: "Select All", value: "selectAll" };
  const allSelected = value.length === options.length;
  const onChange = (event, newValue) => {
    if (newValue.some(option => option.value === "selectAll")) {
      const isSelected = allSelected;
      handleChange(isSelected ? [] : options);
    } else {
      const filteredValues = newValue.filter(option => option.value !== "selectAll");
      handleChange(filteredValues);
    }
  };

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      options={[selectAllOption, ...options]}
      value={value}
      onChange={onChange}
      disableCloseOnSelect
      autoFocus={autoFocus}
      getOptionLabel={(option) => option.label ? option.label : ""}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option, { selected }) => {
        const { id, key, ...otherProps } = props;
        return (
          <li key={id} {...otherProps} style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: selected ? 'lightgray' : 'white' }}>
            <Checkbox
              style={{ marginRight: 8, padding: 0 }}
              checked={option.value === "selectAll" ? allSelected : selected}
            />
            <ListItemText primary={option.label} style={{ backgroundColor: selected ? 'lightgray' : 'white', padding: '0 4px', width: 'auto' }} />
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          required={required}
          label={headerName}
          placeholder="Search..."
          size="small"
          autoFocus={autoFocus}
          InputLabelProps={{
            sx: {
              fontSize: '14px',
              lineHeight: '22px',
            },
          }}
        />
      )}
      size="small"
      PopperComponent={styled(Popper)(({ theme }) => ({
        '&.MuiPopper-root.MuiAutocomplete-popper': {
          width: 'auto !important',
        },
      }))}
    />
  );
}

export default MultipleSelectCheckmarks;
