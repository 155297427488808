import { Clear, LocalOffer, PhotoSizeSelectActualOutlined, ReceiptLong, Search, VideocamOutlined, WorkspacePremiumOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Switch } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import { getValues } from "../common/function/common";
import CertificateModal from "../components/CertificateModal";
import DataGridComponent from "../components/DataGridComponent";
import ImgVideoModal from "../components/diamond-img-video-modal";
import FilterComponent from "../components/filter";
import Loader from "../components/loader";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import NumberPicker from "../components/NumberPicker";
import ToastBar from "../components/ToastBar";
import VendorMemoView from "./../vendor-memo/vendor-memo-view";
import MemoView from "./memo-view";
import SaleView from "./sale-view";
import FindeModal from "../components/FindeModal";

const Inventory = React.memo(({ type_id, form_name }) => {
    const boxRef = useRef(null);
    const dataGridRef = useRef(null);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const inventoryViewAPI = usePostData(`/inventory_view`);
    const masterFillAPI = usePostData(`/master_fill`);
    const [columnResponse, setColumnResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [filterData, setFilterData] = useState({
        shape_name: [],
        color_name: [],
        clarity_name: [],
        cut_name: [],
        pol_name: [],
        sym_name: [],
        lab_name: [],
        color_shade_name: [],
        location_name: [],
        other_company_name: [],
        bt_name: [],
        bc_name: [],
        wt_name: [],
        wc_name: [],
        to_name: [],
        po_name: [],
        co_name: [],
    });
    const [rows, setRows] = useState([]);
    const [advanceOpt, setAdvanceOpt] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const [selectedIds, setSelectedIds] = useState({
        shapeFilter: [],
        colorFilter: [],
        clarityFilter: [],
        cutFilter: [],
        polFilter: [],
        symFilter: [],
        labFilter: [],
        color_shadeFilter: [],
        locationFilter: [],
        other_company_name: [],
        milkyFilter: [],
        btFilter: [],
        bcFilter: [],
        wtFilter: [],
        wcFilter: [],
        toFilter: [],
        poFilter: [],
        coFilter: [],
        is_nobgm: false
    });
    const [values, setValues] = useState({ from_carat: '00.00', from_carat: '00.00' });
    const [selectedDiamondIds, setSelectedDiamondIds] = useState([]);
    const [openMemoForm, setOpenMemoForm] = useState(null);
    const [openVendorMemoForm, setOpenVendorMemoForm] = useState(null);
    const [openSaleForm, setOpenSaleForm] = useState(null);
    const [clearInput, setClearInput] = useState(false);
    const [boxHeight, setBoxHeight] = useState(0);
    const [imgVideoUrl, setImgVideoUrl] = useState("");
    const [certificate, setCertificate] = useState("");
    const [toastMess, setToastMess] = useState(null);
    const [summaryItems, setSummaryItems] = useState([]);
    const [findModal, setFindModal] = useState({flag: false, data: null});

    useEffect(() => {
        const handleResize = () => {
            if (boxRef.current) {
                const height = boxRef.current.clientHeight;
                setBoxHeight(height + 80);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [rows, advanceOpt]);

    const handleSelectionChange = useCallback((filterKey, selectedButtons) => {
        setSelectedIds((prevState) => ({
            ...prevState,
            [filterKey]: selectedButtons,
        }));
    }, []);

    const handleSelectFilter = (newValue, name) => {
        const selectedValues = newValue.map(option => option);
        setSelectedIds({ ...selectedIds, [name]: selectedValues })
    };

    const handle = {
        viewMemo: (d_id) => {
            if (selectedIds?.other_company_name?.length) {
                setOpenVendorMemoForm(d_id)
            } else setOpenMemoForm(d_id)
        },
        viewSale: (d_id) => {
            setOpenSaleForm(d_id)
        },
        serachFilter: () => {
            fetchData()
        },
        clearFilters: () => {
            setClearFilters(true); // Trigger clearing in child components

            // Reset clearFilters after filters are cleared
            setTimeout(() => {
                setClearFilters(false);
                setSelectedIds(prevState => {
                    const resetFilters = [
                        'shapeFilter', 'colorFilter', 'clarityFilter', 'cutFilter', 'polFilter',
                        'symFilter', 'labFilter', 'color_shadeFilter', 'locationFilter', 'other_company_name',
                        'milkyFilter', 'btFilter', 'bcFilter', 'wtFilter', 'wcFilter', 'toFilter', 'poFilter',
                        'coFilter'
                    ];

                    // Create a new object with only the properties that need to be reset
                    const updatedState = resetFilters.reduce((acc, filter) => {
                        acc[filter] = [];
                        return acc;
                    }, {});

                    // Reset the rest of the properties like is_nobgm
                    updatedState.is_nobgm = false;

                    return {
                        ...prevState,
                        ...updatedState // Merge the updated state with the previous state
                    };
                });
                setRows([]);
                setClearInput(prevState => !prevState);
                if (dataGridRef.current) {
                    dataGridRef.current.clearFiltersAndSorting();
                }
            }, 0); // Reset after clearing to avoid re-render loops
        },
    }

    const memoizedOnChange = useCallback((key, value) => {
        setValues((prev) => ({ ...prev, [key]: value }));
    }, []);

    const fetchMasterData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });
            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [shape, color, clarity, cut, pol, sym, lab, color_shade, location, milky, bt, bc, wt, wc, to, po, co, otherLocation] = await Promise.all([
                fetchMasterData("select", "shape", "shape_name"),
                fetchMasterData("select", "color", "color_name"),
                fetchMasterData("select", "clarity", "clarity_name"),
                fetchMasterData("select", "cut", "cut_name"),
                fetchMasterData("select", "pol", "pol_name"),
                fetchMasterData("select", "sym", "sym_name"),
                fetchMasterData("select", "lab", "lab_name"),
                fetchMasterData("select", "color_shade", "color_shade_name"),
                fetchMasterData("select", "diamond_location", "location_name"),
                fetchMasterData("select", "milky", "milky_name"),
                fetchMasterData("select", "bt", "bt_name"),
                fetchMasterData("select", "bc", "bc_name"),
                fetchMasterData("select", "wt", "wt_name"),
                fetchMasterData("select", "wc", "wc_name"),
                fetchMasterData("select", "to", "to_name"),
                fetchMasterData("select", "po", "po_name"),
                fetchMasterData("select", "co", "co_name"),
                fetchMasterData("select", "other_company", "other_company_name"),
            ]);

            setFilterData({
                ...shape, ...color, ...clarity, ...cut, ...pol, ...sym, ...lab, ...color_shade, ...location, ...milky, ...bt, ...bc, ...wt, ...wc, ...to, ...po, ...co, ...otherLocation
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchFieldData = {
        type_id: type_id,
        form_name: form_name,
    };

    const renderCellContent = useCallback((e) => (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <Box onClick={() => setImgVideoUrl({ url: e?.data?.image_url, type: 'image' })} sx={{ cursor: !e?.data?.image_url ? "no-drop" : "pointer", opacity: !e?.data?.image_url ? 0.6 : 1 }}>
                <PhotoSizeSelectActualOutlined sx={{ fontSize: "14px" }} />
            </Box>
            <Box onClick={() => setImgVideoUrl({ url: e?.data?.video_url, type: 'video' })} sx={{ cursor: !e?.data?.video_url ? "no-drop" : "pointer", opacity: !e?.data?.video_url ? 0.6 : 1 }}>
                <VideocamOutlined sx={{ fontSize: "14px" }} />
            </Box>
            <Box onClick={() => setCertificate(e?.data?.certi_url)} sx={{ cursor: !e?.data?.certi_url ? "no-drop" : "pointer", opacity: !e?.data?.certi_url ? 0.6 : 1 }}>
                <WorkspacePremiumOutlined sx={{ fontSize: "14px" }} />
            </Box>
        </Box>
    ), [setImgVideoUrl, setCertificate]);

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync(fetchFieldData);
            const alignmentMap = {
                3: 'right',
                2: 'center',
                1: 'left'
            };
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                textAlign: alignmentMap[col?.column_align],
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
                groupName: col?.group_name,
                ...(col?.column_format && {format: { type: "fixedPoint", precision: col?.column_format }}),
                ...(col.column_name === 'link' && {cellRender: col.column_name === 'link' ? renderCellContent : undefined})
            }));

            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async (findData) => {
        setLoading(true);
        try {
            const responseData = await inventoryViewAPI.mutateAsync({
                shape_code: selectedIds?.shapeFilter?.join(","),
                color_code: selectedIds?.colorFilter?.join(","),
                clarity_code: selectedIds?.clarityFilter?.join(","),
                cut_code: selectedIds?.cutFilter?.join(","),
                pol_code: selectedIds?.polFilter?.join(","),
                sym_code: selectedIds?.symFilter?.join(","),
                lab_code: selectedIds?.labFilter?.join(","),
                color_shade_code: selectedIds?.color_shadeFilter?.join(","),
                location_code: selectedIds?.locationFilter?.join(","),
                other_company_code: getValues(selectedIds.other_company_name),
                bt_code: selectedIds?.btFilter?.join(","),
                bc_code: selectedIds?.bcFilter?.join(","),
                wt_code: selectedIds?.wtFilter?.join(","),
                wc_code: selectedIds?.wcFilter?.join(","),
                to_code: selectedIds?.toFilter?.join(","),
                po_code: selectedIds?.poFilter?.join(","),
                co_code: selectedIds?.coFilter?.join(","),
                is_nobgm: selectedIds?.is_nobgm,
                ...(values?.to_carat && { from_carat: Number(values?.from_carat) }),
                ...(values?.to_carat && { to_carat: Number(values?.to_carat) }),
                ...(findData && findData),
            });

            const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
                id: index + 1,
                ...row
            })) : [];

            setRows(rowsData);
            setFindModal({flag: false, data: null})
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchAllData();
    }, [type_id, form_name]);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    useEffect(() => {
        calculateSummary(rows); // Calculate summary for the initial dataset
    }, [rows]);
    
    const calculateSummary = (data) => {
        const sumRow = {};
        const rowsToSum = data; // Always use the entire dataset for summary calculation

        const amount = rowsToSum.reduce((acc, row) => acc + Number(row['amount'] || 0), 0);
        const caratSum = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0)?.toFixed(3);
        const rapAmountSum = rowsToSum.reduce((acc, row) => acc + Number(row['rap_amount'] || 0), 0);

        const rate = caratSum ? (amount / caratSum).toFixed(2) : 0;
        const rap = caratSum ? (rapAmountSum / caratSum).toFixed(2) : 0;
        const rateDisc = rapAmountSum ? (((amount / rapAmountSum) * 100) - 100).toFixed(4) : 0;

        sumRow['diamond_id'] = rowsToSum?.length;
        sumRow['carat'] = caratSum;
        sumRow['rap'] = rap;
        sumRow['rap_amount'] = rapAmountSum.toFixed(2);
        sumRow['disc'] = rateDisc;
        sumRow['amount'] = amount.toFixed(2);
        sumRow['rate'] = rate;

        setSummaryItems([
            {
                column: "diamond_id", 
                summaryType: "count", // Set this to custom because we're handling it manually
            },
            { column: "carat", summaryType: "custom", displayFormat: `${caratSum}` },
            { column: "rap", summaryType: "custom", displayFormat: `${rap}` },
            { column: "rap_amount", summaryType: "custom", displayFormat: `${rapAmountSum.toFixed(2)}` },
            { column: "disc", summaryType: "custom", displayFormat: `${rateDisc}` },
            { column: "amount", summaryType: "custom", displayFormat: `${amount.toFixed(2)}` },
            { column: "rate", summaryType: "custom", displayFormat: `${rate}` },
        ]);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'F1') {
                event.preventDefault(); // Prevent the default browser help action
                setFindModal({flag: true, data: null});
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if(findModal?.data) fetchData(findModal?.data);
    }, [findModal?.data])

    return (
        <React.Fragment>
            {!masterFillAPI?.isSuccess ? <Loader /> : <Box sx={{ background: "#f9f9f9", height: "100%", flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: "0 0 auto" }}>
                    <Box sx={{ margin: "10px 0 5px 10px" }} ref={boxRef}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Box sx={{ width: "20%" }}>
                                <NumberPicker onChange={memoizedOnChange} clearInput={clearInput} />
                                {/* <TextboxWithPopup/> */}
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                {Array.isArray(filterData.shape_name) && filterData.shape_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="Shape" minWidth="70px"
                                        buttons={filterData.shape_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('shapeFilter', selectedButtons)}
                                    />
                                )}
                            </Box>
                        </Box>
                        {Array.isArray(filterData.color_name) && filterData.color_name.length > 0 && (
                            <FilterComponent clearFilters={clearFilters}
                                title="Color" minWidth="40px"
                                buttons={filterData.color_name}
                                onSelectionChange={(selectedButtons) => handleSelectionChange('colorFilter', selectedButtons)}
                            />
                        )}
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {Array.isArray(filterData.clarity_name) && filterData.clarity_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Clarity" minWidth="40px"
                                    buttons={filterData.clarity_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('clarityFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.cut_name) && filterData.cut_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Cut" minWidth="40px"
                                    buttons={filterData.cut_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('cutFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.pol_name) && filterData.pol_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Pol" minWidth="40px"
                                    buttons={filterData.pol_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('polFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.sym_name) && filterData.sym_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Sym" minWidth="40px"
                                    buttons={filterData.sym_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('symFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.lab_name) && filterData.lab_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Lab" minWidth="40px"
                                    buttons={filterData.lab_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('labFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.color_shade_name) && filterData.color_shade_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Shd" minWidth="40px"
                                    buttons={filterData.color_shade_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('color_shadeFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.location_name) && filterData.location_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Location" minWidth="40px"
                                    buttons={filterData.location_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('locationFilter', selectedButtons)}
                                />
                            )}
                        </Box>
                        <div className={`openAdvance ${advanceOpt ? 'show' : ''}`}>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {Array.isArray(filterData.bt_name) && filterData.bt_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="bt" minWidth="40px"
                                        buttons={filterData.bt_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('btFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.bc_name) && filterData.bc_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="bc" minWidth="40px"
                                        buttons={filterData.bc_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('bcFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.wt_name) && filterData.wt_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="wt" minWidth="40px"
                                        buttons={filterData.wt_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('wtFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.wc_name) && filterData.wc_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="wc" minWidth="40px"
                                        buttons={filterData.wc_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('wcFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.to_name) && filterData.to_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="to" minWidth="40px"
                                        buttons={filterData.to_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('toFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.po_name) && filterData.po_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="po" minWidth="40px"
                                        buttons={filterData.po_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('poFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.co_name) && filterData.co_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="co" minWidth="40px"
                                        buttons={filterData.co_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('coFilter', selectedButtons)}
                                    />
                                )}
                            </Box>
                        </div>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
                        <Box>
                            <FormControl sx={{ maxWidth: "300px", minWidth: "240px" }}>
                                <MultipleSelectCheckmarks
                                    limitTags={1}
                                    options={filterData?.other_company_name || []}
                                    value={selectedIds?.other_company_name || []}
                                    handleChange={(e) => handleSelectFilter(e, "other_company_name")}
                                    headerName={"Other Company"}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: 1, gap: 1 }}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selectedIds?.is_nobgm} color="primary" onChange={() => handleSelectionChange('is_nobgm', !selectedIds?.is_nobgm)} />}
                                label="No BGM"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={advanceOpt}
                                        onChange={(e) => setAdvanceOpt(!advanceOpt)}
                                    />
                                }
                                label={"Advance"}
                            />
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => handle?.serachFilter()}
                                    disabled={loading}
                                    startIcon={<Search />}
                                    sx={{ ...(loading && { color: "white !important" }) }}
                                >
                                    {loading ? "Finding..." : "Find"}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handle?.clearFilters()}
                                    startIcon={<Clear />}
                                >
                                    Clear
                                </Button>
                                {!!selectedDiamondIds?.length && <Button
                                    variant="contained"
                                    onClick={() => handle.viewMemo(selectedDiamondIds)}
                                    startIcon={<ReceiptLong />}
                                >
                                    Memo
                                </Button>}
                                {!!selectedDiamondIds?.length && <Button
                                    variant="contained"
                                    onClick={() => handle.viewSale(selectedDiamondIds)}
                                    startIcon={<LocalOffer />}
                                >
                                    Sale
                                </Button>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ height: `100%`, width: "100%" }}>
                        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                            <DataGridComponent
                                ref={dataGridRef}
                                data={rows}
                                keyExpr="diamond_id"
                                height={`calc(96vh - ${boxHeight}px)`}
                                columns={columnResponse}
                                onSelectionChanged={setSelectedDiamondIds}
                                selectedRows={selectedDiamondIds}
                                stickyIndex={11}
                                rowSelection
                                summaryItems={summaryItems}
                                calculateSummary={calculateSummary}
                                loading={!viewColumnsFillAPI?.isPending && !inventoryViewAPI?.isPending}
                            />
                        </div>
                    </div>
                </Box>
            </Box>}
            {openMemoForm && <MemoView open={openMemoForm} onClose={setOpenMemoForm} />}
            {openVendorMemoForm && <VendorMemoView open={openVendorMemoForm} onClose={setOpenVendorMemoForm} setToastMessage={setToastMess} />}
            {openSaleForm && <SaleView open={openSaleForm} onClose={setOpenSaleForm} />}
            {certificate && <CertificateModal src={certificate} onClose={(() => setCertificate(""))} />}
            {imgVideoUrl && <ImgVideoModal data={imgVideoUrl} onClose={(() => setImgVideoUrl({}))} />}
            {toastMess && <ToastBar open={toastMess} onClose={setToastMess} />}
            {findModal?.flag && <FindeModal findModal={findModal} setFindModal={setFindModal} onClose={() => setFindModal({flag: false, data: null})} loading={loading}/>}
        </React.Fragment >
    );
});

export default Inventory;