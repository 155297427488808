import { Button, DialogContent, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import DModal from "../components/DModal";
import DTextField from "./DTextField";

const FindeModal = React.memo(({ onClose, findModal, setFindModal, loading }) => {

    const [diamondId, setDiamondId] = useState("");
    const [certiNo, setCertiNo] = useState("");

    const handleDiamondIdChange = (event) => {
        setDiamondId(event.target.value);
    };

    const handleCertiNoChange = (event) => {
        setCertiNo(event.target.value);
    };

    const save = () => {
        const formattedDiamondId = diamondId
            .split("\n")
            .map(value => value.trim())
            .filter(Boolean)
            .join(", ");

        const formattedCertiNo = certiNo
            .split("\n")
            .map(value => value.trim())
            .filter(Boolean)
            .join(", ");

        setFindModal({ ...findModal, data: { diamond_id: formattedDiamondId, certi_no: formattedCertiNo } });
    };

    return (
        <React.Fragment>
            <DModal
                open={Boolean(findModal?.flag)}
                backDrop
                modalWidth="600px"
                onClose={onClose}
                DialogTitle={"Find record"}
                DialogContent={
                    <DialogContent style={{ width: "100%", display: "flex", gap: "10px" }} sx={{ padding: "20px !important" }}>
                        <div style={{ width: "50%" }}>
                            <DTextField
                                label={"Diamond ID"}
                                value={diamondId}
                                onChange={handleDiamondIdChange}
                                variant="outlined"
                                fullWidth
                                size="large"
                                multiline
                                rows={4}
                                className="d_find-text"
                                isUppercase={false}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <DTextField
                                label={"Certi No"}
                                value={certiNo}
                                onChange={handleCertiNoChange}
                                variant="outlined"
                                fullWidth
                                size="large"
                                multiline
                                rows={4}
                                className="d_find-text"
                                isUppercase={false}
                            />
                        </div>
                    </DialogContent>
                }
                DialogActions={
                    <>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button variant="contained" disabled={loading} onClick={() => save()}>{loading ? "Finding..." : "Find"}</Button>
                    </>
                }
            />
        </React.Fragment>
    );
});

export default FindeModal;
