import * as MuiIcons from "@mui/icons-material";
import { Logout, Refresh, Cached, SyncLock } from "@mui/icons-material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppBar, Box, Button, ListItemIcon, Toolbar, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { setIpAddress, setMainMenu } from "../action";
import { COLOR } from "../common/constant";
import { usePostData } from "../common/function/api";
import { getLocalStorage } from "../common/function/common";
import { fetchIPAddress } from "../common/function/fetchIPAddress";
import PasswordChange from "../components/PasswordChange";
import ToastBar from "../components/ToastBar";
import Cookies from 'js-cookie';

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchDataAPI = usePostData(`/master_fill`);
    const [toastMess, setToastMess] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPassModal, setOpenPassModal] = React.useState(null);
    const mainMenu = JSON.parse(localStorage.getItem("mainMenu")) || null;
    const userData = getLocalStorage("userAuth");
    const location = useLocation();
    const [refreshKey, setRefreshKey] = useState(0);

    const handleMenuClick = (item) => {
        const currentPath = location.pathname;

        if (item.menu_path && currentPath !== item.menu_path) {
            // If the clicked menu path is different, navigate to that path
            navigate(item.menu_path);
        } else {
            // If the menu is already active, force a re-render by changing the key
            setRefreshKey(prevKey => prevKey + 1);  // This triggers the re-render
        }
    };

    useEffect(() => {
        // This hook will run whenever the location changes, causing a fresh render
    }, [location]);
    const fetchData = async () => {
        try {
            const responseData = await fetchDataAPI.mutateAsync({
                mast_name: "menu"
            });

            if (responseData) {
                if (mainMenu) {
                    setToastMess("Menu Synced Successfully!!");
                }
                localStorage.setItem('mainMenu', JSON.stringify(responseData));
                dispatch(setMainMenu(responseData));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (!mainMenu || mainMenu?.length === 0) fetchData();
    }, [mainMenu]);

    useEffect(() => {
        const getIPAddress = async () => {
            const ip = await fetchIPAddress();
            dispatch(setIpAddress(ip));
        };
        getIPAddress();
    }, []);

    let arr = mainMenu?.filter((item) => !mainMenu?.some((i) => i.menu_id === item.menu_parent_id)).map((e) => e);

    const getIconComponent = (iconName, color) => {
        const IconComponent = MuiIcons[iconName];
        return IconComponent ? <IconComponent sx={{ color: color ? color : COLOR.white }} /> : <MuiIcons.Help sx={{ color: COLOR.white }} />;
    };

    const handle = {
        handleMenu: (event) => {
            setAnchorEl(event.currentTarget);
        },
        logOut: () => {
            window.location.reload();
            setAnchorEl(null);
            localStorage.clear();
        },
        handleClose: () => {
            setAnchorEl(null);
        },
        refreshMenu: () => {
            fetchData();
        },
        changePassword: () => {
            setOpenPassModal(true)
        },
        cacheClear: () => {
            const handleClearCache = async () => {
                // This will remove all cookies
                const allCookies = Cookies.get(); // Get all cookies
                for (const cookie in allCookies) {
                    if (allCookies.hasOwnProperty(cookie)) {
                        Cookies.remove(cookie); // Remove each cookie
                    }
                }
                if ('caches' in window) {
                    try {
                        const cacheNames = await caches.keys(); // Get all cache keys
                        await Promise.all(
                            cacheNames.map((cacheName) => caches.delete(cacheName)) // Delete each cache
                        );
                        window.location.reload();
                        console.log('Cache cleared!');
                        setToastMess('Cache cleared successfully!');
                    } catch (error) {
                        console.error('Error clearing cache:', error);
                    }
                } else {
                    console.warn('Cache API is not supported in this browser.');
                }
            };
            handleClearCache();
        }
    };

    return (
        <React.Fragment>
            <AppBar position="static" sx={{ boxShadow: "none", background: 'linear-gradient(360deg, #2476ca 0%, #174ea6 100%)' }}>
                <div className="d_flex-align d_content-between" style={{ margin: "0 10px", overflowX: 'auto', position: "relative" }}>
                    <Toolbar sx={{ padding: "0 !important", minHeight: "40px !important" }}>
                        <img src="/images/dholakia-logo.png" height="45px" style={{ filter: "brightness(0) invert(1)" }} />
                        {arr?.map((item, i) => {
                            const hasSubMenu = mainMenu.some((subItem) => subItem.menu_parent_id === item.menu_id && subItem.is_active);

                            if (!hasSubMenu) {
                                // If no submenu, render only the IconButton
                                return item?.is_active && (
                                    <IconButton
                                        key={item.menu_id}
                                        size="medium"
                                        sx={{
                                            '&:hover': {
                                                borderRadius: 1,
                                                backgroundColor: "rgba(0, 0, 0, 0.15)"
                                            },
                                            alignItems: "center",
                                            color: "white",
                                            transition: "all 0.2s ease-in-out"
                                        }}
                                        onClick={() => handleMenuClick(item)}
                                    >
                                        {getIconComponent(item.menu_image_name)}
                                        <p style={{
                                            color: "white",
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            paddingTop: "1px",
                                            paddingLeft: "5px"
                                        }}>
                                            {item.menu_short_name}
                                        </p>
                                    </IconButton>
                                );
                            }

                            return item?.is_active && (
                                <Popup
                                    key={item.menu_id}
                                    trigger={
                                        <IconButton
                                            size="medium"
                                            sx={{
                                                '&:hover': {
                                                    borderRadius: 1,
                                                    backgroundColor: "rgba(0, 0, 0, 0.15)"
                                                },
                                                alignItems: "center",
                                                color: "white",
                                                transition: "all 0.2s ease-in-out"
                                            }}
                                        >
                                            {getIconComponent(item.menu_image_name)}
                                            <p style={{
                                                color: "white",
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                                paddingTop: "1px",
                                                paddingLeft: "5px"
                                            }}>
                                                {item.menu_short_name}
                                            </p>
                                        </IconButton>
                                    }
                                    position="bottom center"
                                    on="hover"
                                    closeOnDocumentClick
                                    mouseLeaveDelay={200}
                                    mouseEnterDelay={0}
                                    contentStyle={{
                                        padding: "2px 10px",
                                        borderRadius: "5px",
                                        boxShadow: "0px 2px 7px 3px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: COLOR.lightGray,
                                        height: "unset",
                                        width: "auto"
                                    }}
                                    arrow={true}
                                    onOpen={() => {
                                        document.activeElement.blur();
                                    }}
                                >
                                    {close => (
                                        mainMenu
                                            .filter((subItem) => subItem.menu_parent_id === item.menu_id && subItem.is_active)
                                            .map((subItem, index) => (
                                                <Button
                                                    key={index}
                                                    onClick={() => {
                                                        handleMenuClick(subItem);
                                                        close();
                                                    }}
                                                    sx={{
                                                        minWidth: '85px !important',
                                                        color: COLOR.black,
                                                        fontWeight: 500,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        textAlign: 'left',
                                                        padding: '5px 10px',
                                                        '&:hover': {
                                                            backgroundColor: '#e0e0e0',
                                                        }
                                                    }}
                                                    tabIndex={-1}
                                                >
                                                    {getIconComponent(subItem.menu_image_name, "#000000")}
                                                    {subItem.menu_short_name}
                                                </Button>
                                            ))
                                    )}
                                </Popup>
                            );
                        })}

                    </Toolbar>
                    {userData && (
                        <div>
                            <div className="d_flex-align">
                                <Tooltip title="Cache Clear">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={handle.cacheClear} size="small" sx={{ ml: 2, color: "white" }}>
                                            <Cached sx={{ color: "white" }} />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Refresh Menu">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={handle.refreshMenu} size="small" sx={{ color: "white" }}>
                                            <Refresh sx={{ color: "white" }} />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Account settings">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton
                                            onClick={handle.handleMenu}
                                            size="small"
                                            aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                        >
                                            <AccountCircle sx={{ color: "white", transition: "all 0.2s ease-in-out" }} />
                                        </IconButton>
                                        {userData?.email && <h5 style={{ fontWeight: "normal", color: "white", margin: "0 8px" }}>{userData?.email}</h5>}
                                    </Box>
                                </Tooltip>
                            </div>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={Boolean(anchorEl)}
                                onClose={handle.handleClose}
                                onMouseLeave={handle.handleClose}
                                sx={{
                                    '& .MuiPaper-root': {
                                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                                        mt: 1.5,
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handle.changePassword}>
                                    <ListItemIcon>
                                        <SyncLock fontSize="small" />
                                    </ListItemIcon>
                                    Change Password
                                </MenuItem>
                                <MenuItem onClick={handle.logOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
            </AppBar>
            <div className="d_layout" key={refreshKey}>
                {children || <Outlet />}
            </div>
            <PasswordChange open={openPassModal} onClose={() => {
                setOpenPassModal(false)
                handle.handleClose()
            }} successMessage={toastMess} setSuccessMessage={setToastMess} />
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    );
};

export default Layout;
