import React, { useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Login from "../app-dt-sell/auth/login";
import Branch from "../app-dt-sell/branch-details/branch";
import Company from "../app-dt-sell/company";
import NotFound from "../app-dt-sell/components/NotFound";
import Dashboard from "../app-dt-sell/dashboard";
import Employee from "../app-dt-sell/employee";
import FormPermission from "../app-dt-sell/form-permission";
import Inventory from "../app-dt-sell/inventory";
import LabSend from "../app-dt-sell/lab-send";
import PartyStockCriteria from "../app-dt-sell/pricing/party_stock_criteria";
import PartyStockDiscount from "../app-dt-sell/pricing/party_stock_discount";
import Memo from "../app-dt-sell/memo-view";
import Party from "../app-dt-sell/party-details/party";
import PartyDetails from "../app-dt-sell/party-details/party-details";
import Sale from "../app-dt-sell/sale-view";
import SpecialCut from "../app-dt-sell/special-cut";
import Layout from "./auth/layout";
import PartyStockSchedule from "./pricing/party_stock_schedule";
import PartyStockNotMatchParameter from "./pricing/party_stock_not_match_parameter";
import VendorMemo from "./vendor-memo";
import PartyStockColumn from "./pricing/party_stock_column";
import Rap from "./pricing/rap";
import StockView from "./reports/stock-view";
import StockLevelView from "./reports/stock-level-view";
import StockPriceCompareView from "./reports/stock-price-compare-view";

function RoutesConfig() {
    const navigate = useNavigate();
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/" element={<AuthRoute><Login /></AuthRoute>} />
            {/* Private Routes */}
            <Route element={<PrivateRoute><Layout/></PrivateRoute>}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/company" element={<Company type_id={0} form_name="Company" />} />
                <Route path="/branch" element={<Branch type_id={0} form_name="Branch" />} />
                <Route path="/party" element={<Party type_id={0} form_name="Party" />} />
                <Route path="/party/details" element={<PartyDetails type_id={0} form_name="Party" />} />
                <Route path="/employee" element={<Employee type_id={0} form_name="Employee" />} />
                <Route path="/inventory" element={<Inventory type_id={0} form_name="Inventory" />} />
                <Route path="/memo" element={<Memo type_id={0} form_name="Memo" />} />
                <Route path="/other_company_memo" element={<VendorMemo type_id={0} form_name="other company memo" />} />
                <Route path="/sale" element={<Sale type_id={0} form_name="Sale" />} />
                <Route path="/lab_send" element={<LabSend type_id={0} form_name="lab send" />} />
                <Route path="/party_stock_criteria" element={<PartyStockCriteria type_id={0} form_name="party stock criteria" />} />
                <Route path="/party_stock_column" element={<PartyStockColumn type_id={0} form_name="party stock column" />} />
                <Route path="/rap" element={<Rap type_id={0} form_name="rap" />} />
                <Route path="/party_stock_discount" element={<PartyStockDiscount type_id={0} form_name="party stock discount" />} />
                <Route path="/party_stock_schedule" element={<PartyStockSchedule type_id={0} form_name="party stock schedule" />} />
                <Route path="/stock_view" element={<StockView type_id={0} form_name="stock view" />} />
                <Route path="/stock_level_view" element={<StockLevelView type_id={0} form_name="stock level view" />} />
                <Route path="/stock_price_compare_view" element={<StockPriceCompareView type_id={0} form_name="stock price compare view" />} />
                <Route path="/party_stock_not_match_parameter" element={<PartyStockNotMatchParameter type_id={0} form_name="party stock not match parameter" />} />
                <Route path="/form_permission" element={<FormPermission type_id={0} form_name="form permission" />} />
            </Route>
            <Route path="/special-cut" element={<SpecialCut type_id={0} form_name="special-cut" />} />

            {/* Catch-All Route */}
            <Route path="*" element={<PrivateRoute><NotFound btn btnTitle="Go to dashboard" btnOnClick={() => navigate("/")}/></PrivateRoute>} />
        </Routes>
    );
}

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("userAuth");

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token, navigate]);

    if (!token) {
        return null;
    }

    return children ? children : <Outlet />;
};

const AuthRoute = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("userAuth");

    useEffect(() => {
        if (token) {
            navigate("/dashboard");
        } else {
            navigate("/");
        }
    }, [token, navigate]);

    if (token) {
        return null;
    }

    return children;
};

export default RoutesConfig;
