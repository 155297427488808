import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { usePostData } from "../common/function/api";
import ConfirmationModal from "../components/confirmationModal";
import EditableDataGrid from "../components/InlineDatGrid";
import ToastBar from "../components/ToastBar";

const PartyStockColumn = (props) => {
    const masterFillAPI = usePostData(`/master_fill`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/master_view`);
    const deleteAPI = usePostData(`/party_stock_column_delete`);
    const saveAPI = usePostData(`/party_stock_column_save`);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        party_name: [],
        column_name: []
    });
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
    const [toastMess, setToastMess] = useState("");
    const [error, setError] = useState("");
    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                mast_type: mastType,
                mast_name: mastName,
            });

            // Ensure unique options
            const uniqueData = Array.from(
                new Map(responseData.map(item => [item.label, item])).values()
            );

            return { [key]: uniqueData };
        } catch (error) {
            console.error("Error:", error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [partyName, columnName] = await Promise.all([
                fetchData("select", "party", "party_name"),
                fetchData("select", "stock_column", "column_name"),
            ]);
            setOptions({ ...partyName, ...columnName });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "party_stock_column"
            });
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
                is_editable: col?.is_editable,
                ...(col.column_name === 'column_name' ? { options: options?.column_name || [] } : {}),
                cell: (cell) => (
                    ["switch"].includes(col.column_type) ? (
                        <label className="label toggle">
                            <input type="checkbox" readOnly checked={cell.getValue()} className="toggle_input" />
                            <div className="toggle-control"></div>
                        </label>
                    ) : (
                        cell.getValue()
                    )
                )
            }));
            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRowData = async (party_code) => {
        try {
            const responseData = await masterViewAPI.mutateAsync({
                party_code: party_code,
                view_name: "party_stock_column",
            });

            if (responseData) {
                const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({ ...row, })) : [];

                setRows(rowsData);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (options && Object.keys(options).length > 0) {
            fetchColumn();
        }
    }, [options]);

    useEffect(() => {
        fetchAllData();
        fetchColumn();
    }, []);

    const handleChange = (newValue, name) => {
        setFormData({ ...formData, [name]: newValue });
        fetchRowData(newValue?.value)
        setError("")
    }

    const deleteConfirmation = async (id) => {
        try {
            const responseData = await deleteAPI.mutateAsync({
                id: id?.id,
                party_code: id?.party_code
            });

            if (responseData?.success) {
                setRows((prevData) => prevData.filter((item) => item.id !== id?.id));
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message);
            } else {
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleRowInsert = async (e) => {
        // Prevent default insert behavior until API call is resolved
        e.cancel = true;

        const newRow = { ...e.data }; // Temporary ID for the new row
        try {
            const responseData = await saveAPI.mutateAsync({
                ...newRow,
                party_code: formData?.party?.value,
            });

            if (responseData?.success) {
                setToastMess(responseData?.message);
                // Add the new row with the correct ID
                setRows((prevData) => [
                    ...prevData,
                    { ...newRow, id: responseData.id }, // Update with actual ID
                ]);
                e.component.cancelEditData(); // Explicitly close editing on success
                setError("")
            } else {
                setError(responseData?.message); // Show error message
            }
        } catch (error) {
            console.error('Error while saving:', error);
            setError('An unexpected error occurred.');
        }
    };

    const handleRowUpdated = (e) => {
        const updatedData = e.data;
        const rowUpdated = async () => {
            try {
                const responseData = await saveAPI.mutateAsync({
                    ...updatedData,
                    party_code: formData?.party?.value // Add any other fields needed
                });
                if (responseData?.success) {
                    // If API call is successful, update the row in the state
                    const updatedRows = rows.map((row) =>
                        row.id === e.key ? { ...row, ...updatedData } : row
                    );
                    setRows(updatedRows);
                    setToastMess(responseData?.message); // Show success message
                } else {
                    setError(responseData?.message); // Show error message if any
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        rowUpdated();
    };

    const handleRowDeleted = async (id) => {
        try {
            const responseData = await deleteAPI.mutateAsync({
                id: id?.id,
                party_code: formData?.party?.value
            });
            if (responseData?.success) {
                setToastMess(responseData?.message);
                setRows((prevData) => prevData.filter((item) => item.id !== id));
            } else setError(responseData?.message)
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const memoizedColumns = useMemo(() => columnResponse, [columnResponse]);
    const memoizedRows = useMemo(() => rows, [rows]);

    return (
        <React.Fragment>
            <Box sx={{ p: 2 }}>
                <div className="d_row" style={{ width: "100%" }}>
                    <div className="d_col-3">
                        <FormControl fullWidth>
                            <Autocomplete
                                value={formData?.party || null}
                                onChange={(event, newValue) => handleChange(newValue, 'party')}
                                options={options?.party_name || []}
                                getOptionLabel={(option) => option?.label ?? ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Party"}
                                    />
                                )}
                                size="small"
                                disableClearable={true}
                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            />
                        </FormControl>
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </div>
                </div>
            </Box>
            {memoizedColumns &&
                <EditableDataGrid
                    data={memoizedRows}
                    columns={memoizedColumns}
                    onRowUpdated={handleRowUpdated}
                    onRowInserted={handleRowInsert}
                    onRowDeleted={handleRowDeleted}
                    allowDeleting={true}
                    allowUpdating={true}
                    allowAdding={formData?.party}
                    height={`calc(100vh - 150px)`}
                    dropdownOptions={options}
                    className="custom-add-row-btn"
                />
            }
            <ConfirmationModal
                open={openDeletemodal?.flag}
                onClose={() => setOpenDeletemodal({ flag: false, data: null })}
                header={"Delete Party?"}
                title={
                    <>Are you sure you want to delete the criteria?</>
                }
                action={() => deleteConfirmation(openDeletemodal?.data)}
            />
            {toastMess && <ToastBar open={toastMess} onClose={setToastMess} />}
        </React.Fragment>
    )
};

export default PartyStockColumn;