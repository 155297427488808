import { Autocomplete, Box, Button, DialogContent, FormControl, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import { createLabelValue } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import ExcelJsonViewer from "../components/ExcelReader";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import EditMemoDiamond from "../memo-view/edit-memo-diamond";
import DCustomDatePicker from "../components/DCustomDatePicker";

const MemoView = React.memo(({ open, onClose, setMemoRows, type, setType, withExcel }) => {
  const masterFillAPI = usePostData(`/master_fill`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const memoViewAPI = usePostData(`/memo_view`);
  const memoSave = usePostData(`/memo_save`);
  const deleteAPI = usePostData(`/memo_delete`);
  const memoUtilitySave = usePostData(`/memo_utility_save`);
  const [columns, setColumns] = useState([]);
  const [selectData, setSelectData] = useState({
    memo_type_name: [],
    party_name: [],
    broker_name: [],
    party_member_name: [],
    reason_name: [],
    broker_member_name: [],
  });
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openMemoForm, setOpenMemoForm] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  const [selectedMemoIds, setSelectedMemoIds] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const fetchRows = async () => {
    setLoading(true);
    try {
      const responseData = await memoViewAPI.mutateAsync({
        "trans_name": type === "merge" ? "memo_merge_by_no" : type === "split" ? "memo_split_by_no" : !open?.memo_no ? "memo_diamond_detail_by_id" : "memo_data_by_no",
        "memo_no": type ? open : open?.memo_no ?? 0,
        ...((!type && !open?.memo_no) && { "diamond_id": open?.join(",") })
      });
      let responseDatas = responseData?.detail ?? responseData;
      const rowsData = responseDatas?.length !== 0 ? responseDatas?.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];
      setRows(rowsData);
      let dataSummary = responseData?.summary[0] ?? null;
      if (dataSummary !== null && type) {
        const convertToDayjs = (dateStr) => {
          if (!dateStr) return null;
          const [day, month, year] = dateStr.split('-');
          return dayjs(`${year}-${month}-${day}`);
        };
        setFormData({
          ...dataSummary,
          ...(dataSummary?.party_code && { party_name: createLabelValue(dataSummary?.party_name, dataSummary?.party_code) }),
          ...(dataSummary?.memo_type_id && { memo_type_name: createLabelValue(dataSummary?.memo_type_name, dataSummary?.memo_type_id) }),
          ...(dataSummary?.reason_id && { reason_name: createLabelValue(dataSummary?.reason_name, dataSummary?.reason_id) }),
          ...(dataSummary?.party_member_id && { party_member_name: createLabelValue(dataSummary?.party_member_name, dataSummary?.party_member_id) }),
          ...(dataSummary?.broker_code && { broker_name: createLabelValue(dataSummary?.broker_name, dataSummary?.broker_code) }),
          ...(dataSummary?.broker_member_id && { broker_member_name: createLabelValue(dataSummary?.broker_member_name, dataSummary?.broker_member_id) }),
        })
        setSelectedDates({ ...selectedDates, memo_date: convertToDayjs(dataSummary?.memo_date) });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: 0,
        form_name: "memo",
        view_name: "memo_detail",
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form,
        is_editable: col?.is_editable
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true,
        left: 0
      };
      columns.splice(0, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };

  const fetchAllData = async () => {
    try {
      const [memoTypeName, partyName, brokerName, memoReasonName] = await Promise.all([
        fetchData("select", "memo_type", "memo_type_name"),
        fetchData("select", "party", "party_name"),
        fetchData("select", "broker", "broker_name"),
        fetchData("select", "memo_reason", "reason_name"),
      ]);

      setSelectData({
        ...memoTypeName, ...partyName, ...brokerName, ...memoReasonName
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPartyData = (party_code, type) => {
    masterFillAPI.mutate({
      "mast_type": "select",
      "mast_name": "party_member",
      party_code: party_code
    }, {
      onSuccess: (responseData) => {
        setSelectData((prevData) => ({
          ...prevData,
          [type]: responseData
        }));
      },
      onError: (error) => {
        console.error('Error:', error); // Log the error
      },
    });
  };

  const saveMemo = ({ values, table }) => {
    const saveAPI = type ? memoUtilitySave : memoSave
    saveAPI.mutate({
      ...formData,
      memo_no: formData?.memo_no ?? 0,
      process_id: 0,
      ...(type && { save_name: type }),
      diamond_data_list: rows.map((row) => ({ ...row, ...(type && { is_select: selectedMemoIds.includes(row.diamond_id) }) })),
      party_code: formData?.party_name?.value,
      memo_type_id: formData?.memo_type_name?.value,
      reason_id: formData?.reason_name?.value,
      party_member_id: formData?.party_member_name?.value,
      broker_code: formData?.broker_name?.value,
      broker_member_id: formData?.broker_member_name?.value,
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          const parseData = JSON?.parse(responseData?.request_body_data)
          if (type) {
            if (formData?.memo_type_no) {
              setMemoRows((prevData) =>
                prevData.map((item) =>
                  item.memo_type_no === formData?.memo_type_no
                    ? {
                      ...parseData,
                      ...formData,
                      party_name: parseData?.party_name?.label,
                      memo_type_name: parseData?.memo_type_name?.label,
                      reason_name: parseData?.reason_name?.label,
                      party_member_name: parseData?.party_member_name?.label,
                      broker_name: parseData?.broker_name?.label,
                      broker_member_name: parseData?.broker_member_name?.label,
                    }
                    : item
                )
              );
            } else {
              // Push new data to memo rows
              setMemoRows((prevData) => [
                ...prevData,
                {
                  ...parseData,
                  ...formData,
                  memo_no: responseData?.memo_no,
                  memo_type_no: responseData?.memo_type_no,
                  party_name: parseData?.party_name?.label,
                  memo_type_name: parseData?.memo_type_name?.label,
                  reason_name: parseData?.reason_name?.label,
                  party_member_name: parseData?.party_member_name?.label,
                  broker_name: parseData?.broker_name?.label,
                  broker_member_name: parseData?.broker_member_name?.label,
                }
              ]);
            }
          }
          setToastMess(responseData?.message)
          handleClose()
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
        setError(error);
      }
    });
  }

  const handleDelete = async (data) => {
    deleteAPI.mutate({
      memo_no: data?.memo_no,
      diamond_id_list: data?.diamond_id
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.diamond_id !== data?.diamond_id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const updateTableRows = (excelData) => {
    const updatedRows = rows.map(row => {
      const matchingExcelRow = excelData.find(
        (excelRow) => excelRow["Diamond ID"] === row.diamond_id
      );

      if (matchingExcelRow) {
        let updatedRow = { ...row };

        // Update fields based on Excel data
        let { Disc, Rate, Amount } = matchingExcelRow;

        // If any value is missing, calculate using the available ones
        Disc = Disc || ((Rate / updatedRow.rap * 100) - 100).toFixed(2); // Fallback to formula if missing
        Rate = Rate || ((updatedRow.rap + (updatedRow.rap * Disc / 100)).toFixed(2)); // Fallback to formula if missing
        Amount = Amount || (Rate * updatedRow.carat).toFixed(2); // Fallback to formula if missing

        // Apply formulas based on the provided data
        updatedRow.memo_disc = Disc;
        updatedRow.memo_rate = Rate;
        updatedRow.memo_amount = Amount;

        // 1st formula: When memo_disc changes, update memo_rate and memo_amount
        if (Disc) {
          updatedRow.memo_rate = (updatedRow.rap + (updatedRow.rap * Disc / 100)).toFixed(2);
          updatedRow.memo_amount = (updatedRow.memo_rate * updatedRow.carat).toFixed(2);
        }

        // 2nd formula: When memo_rate changes, update memo_amount and memo_disc
        if (Rate && !Disc) {
          updatedRow.memo_amount = (Rate * updatedRow.carat).toFixed(2);
          updatedRow.memo_disc = ((Rate / updatedRow.rap * 100) - 100).toFixed(2);
        }

        // 3rd formula: When memo_amount changes, update memo_rate and memo_disc
        if (Amount && !Rate && !Disc) {
          updatedRow.memo_rate = (Amount / updatedRow.carat).toFixed(2);
          updatedRow.memo_disc = ((updatedRow.memo_rate / updatedRow.rap * 100) - 100).toFixed(2);
        }

        // 4th formula: When carat changes, update memo_amount (depends on memo_rate)
        if (updatedRow.carat) {
          updatedRow.memo_amount = (updatedRow.memo_rate * updatedRow.carat).toFixed(2);
        }

        return updatedRow;
      }
      return row;
    });

    // Set the updated rows
    setRows(updatedRows);
  };

  useEffect(() => {
    if (open?.memo_no || open?.length || type) {
      fetchColumn();
      fetchRows();
    }
  }, [open?.memo_no, open?.length, type]);
  useEffect(() => {
    if (formData?.party_name?.value) {
      fetchPartyData(formData.party_name.value, 'party_member_name');
    }
  }, [formData?.party_name?.value]);

  useEffect(() => {
    if (formData?.broker_name?.value) {
      fetchPartyData(formData.broker_name.value, 'broker_member_name');
    }
  }, [formData?.broker_name?.value]);

  useEffect(() => {
    const fetchAddressColumn = async () => {
      try {
        const responseData = await viewColumnsFillAPI.mutateAsync({
          "type_id": 0,
          "form_name": "memo",
          "view_name": "memo_summary"
        });

        if (responseData) {
          setColumns(responseData.sort((a, b) => a.form_display_ord - b.form_display_ord));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAddressColumn();
    fetchAllData();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleClose = () => {
    onClose(null);
    setError('')
    setFormData({})
    if(window.location.pathname !== "/inventory") setType("")
  };

  const handleEdit = (data) => {
    setOpenMemoForm(data)
  }

  const selectedRowsChange = (memoId) => {
    setSelectedMemoIds(memoId);
  }

  const handleChange = (newValue, columnId, columnType) => {
    if (columnType === "select") {
      if (columnId === "party_name") {
        setFormData({
          ...formData,
          [columnId]: newValue,
          party_member_name: null,
        });
      } else if (columnId === "broker_name") {
        setFormData({
          ...formData,
          [columnId]: newValue,
          broker_member_name: null,
        });
      } else {
        setFormData({ ...formData, [columnId]: newValue });
      }
    } else if (columnType === "switch") {
      setFormData({ ...formData, [columnId]: newValue.target.checked });
    } else {
      setFormData({ ...formData, [columnId]: newValue.target.value });
    }
  };

  useEffect(() => {
    if (open?.memo_no) {
      const convertToDayjs = (dateStr) => {
        if (!dateStr) return null;
        const [day, month, year] = dateStr.split('-');
        return dayjs(`${year}-${month}-${day}`);
      };
      setFormData({
        ...open,
        ...(open?.party_code && { party_name: createLabelValue(open?.party_name, open?.party_code) }),
        ...(open?.memo_type_id && { memo_type_name: createLabelValue(open?.memo_type_name, open?.memo_type_id) }),
        ...(open?.reason_id && { reason_name: createLabelValue(open?.reason_name, open?.reason_id) }),
        ...(open?.party_member_id && { party_member_name: createLabelValue(open?.party_member_name, open?.party_member_id) }),
        ...(open?.broker_code && { broker_name: createLabelValue(open?.broker_name, open?.broker_code) }),
        ...(open?.broker_member_id && { broker_member_name: createLabelValue(open?.broker_member_name, open?.broker_member_id) }),
      })
      setSelectedDates({ ...selectedDates, memo_date: convertToDayjs(open?.memo_date) });
    }
  }, [open?.memo_no])

  const sumRow = {};
  let selectedRows = rows;
  const rowsToSum = selectedRows?.length ? selectedRows : rows;
  const numberColumns = ["memo_amount", "carat", "rap_amount", "memo_rate", "memo_disc", "disc", "rate", "rate_amount"]

  const memoAmountSum = rowsToSum.reduce((acc, row) => acc + Number(row['memo_amount'] || 0), 0);
  const memoCaratSum = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0);
  const rapAmountSum = rowsToSum.reduce((acc, row) => acc + Number(row['rap_amount'] || 0), 0);
  const rateAmountSum = rowsToSum.reduce((acc, row) => acc + Number(row['rate_amount'] || 0), 0);

  const memoRate = memoCaratSum ? (memoAmountSum / memoCaratSum).toFixed(2) : 0;
  const memoDisc = rapAmountSum ? (((memoAmountSum / rapAmountSum) * 100) - 100).toFixed(2) : 0;
  const rate = memoCaratSum ? (rateAmountSum / memoCaratSum).toFixed(2) : 0;
  const rateDisc = rapAmountSum ? (((rateAmountSum / rapAmountSum) * 100) - 100).toFixed(2) : 0;

  sumRow['memo_amount'] = memoAmountSum.toFixed(2);
  sumRow['carat'] = memoCaratSum.toFixed(2);
  sumRow['rap_amount'] = rapAmountSum.toFixed(2);
  sumRow['rate_amount'] = rateAmountSum.toFixed(2);
  sumRow['memo_rate'] = memoRate;
  sumRow['memo_disc'] = memoDisc;
  sumRow['rate'] = rate;
  sumRow['disc'] = rateDisc;

  return (
    <React.Fragment>
      <DModal
        open={Boolean(open)}
        backDrop
        onClose={handleClose}
        DialogTitle={"Memo Entry"}
        DialogContent={
          <>
            <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
              {
                columns &&
                columns?.filter(_ => _?.is_visible_form)?.map((item, index) => {
                  const columnType = item?.column_type;
                  const required = item?.is_required;
                  const columnKey = item?.column_name;
                  const cssClassName = item?.css_class_name;
                  const headerName = item?.column_header;
                  const disabled = item?.is_editable;
                  const isFirstField = index === 2;

                  if (columnType === "text") {
                    return (
                      <div className={cssClassName} key={index}>
                        <DTextField
                          label={headerName}
                          required={required}
                          autoFocus={isFirstField}
                          value={formData[columnKey] || ''}
                          onChange={(e) => handleChange(e, columnKey, columnType)}
                          variant="outlined"
                          fullWidth
                          size="small"
                          disabled={!disabled}
                        />
                      </div>
                    );
                  }
                  if (columnType === 'select') {
                    let options = [];
                    options = selectData[columnKey];
                    return (
                      <div className={cssClassName} key={index}>
                        <FormControl fullWidth>
                          <Autocomplete
                            value={formData[columnKey] || null}
                            onChange={(event, newValue) => handleChange(newValue, columnKey, 'select')}
                            options={options || []}
                            disabled={!disabled}
                            autoFocus={isFirstField}
                            getOptionLabel={(option) => option?.label ?? ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoFocus={isFirstField}
                                required={required}
                                label={headerName}
                                fullWidth
                              />
                            )}
                            size="small"
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                          />

                        </FormControl>
                      </div>
                    );
                  }
                  if (columnType === 'date') {
                    const handleChange = (newValue) => {
                      setSelectedDates((prevDates) => ({
                        ...prevDates,
                        [columnKey]: newValue, // Store the Dayjs object directly
                      }));

                      const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';

                      // Update formData with formatted date
                      setFormData((prevData) => ({ ...prevData, [columnKey]: formattedDate }));
                    };

                    return (
                      <div className={`${cssClassName} member-datepicker`} key={index}>
                        <DCustomDatePicker
                          headerName={headerName}
                          required={true}
                          selectedDate={selectedDates[columnKey]}
                          onChange={handleChange}
                          columnKey={columnKey}
                        />
                      </div>
                    );
                  }
                })
              }
              {error && <em style={{ color: "red" }}>{error}</em>}
            </DialogContent>
            <Box>
              <div style={{ height: "100%", width: "100%" }}>
                {(columnResponse && visibleColumn !== null) &&
                  <VirtualizedTableData
                    height={"440px"} rowSelection={type}
                    data={rows} visibleColumn={visibleColumn}
                    handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
                    handleEdit={(data) => handleEdit(data)}
                    loading={!loading && !viewColumnsFillAPI?.isPending}
                    columnResponse={columnResponse}
                    onSelectedRowsChange={selectedRowsChange}
                    memoSumRow={sumRow}
                    selectedId="diamond_id" numberColumns={numberColumns}
                    uploadExcel={withExcel && <ExcelJsonViewer setJsonData={setJsonData} updateTableRows={updateTableRows} />}
                  />}
              </div>
            </Box>
          </>
        }
        DialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" autoFocus onClick={saveMemo}>Save</Button>
          </>
        }
      />
      <EditMemoDiamond open={openMemoForm} onClose={setOpenMemoForm} setMemoRows={setRows} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Memo?"}
        title={
          <>Are you sure you want to delete this memo?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
      />
      <ToastBar open={toastMess} onClose={setToastMess} />
    </React.Fragment>
  );
});

export default MemoView;
