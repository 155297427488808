import { DataObject } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Fade,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import React, { useState } from "react";

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  padding: theme.spacing(3),
  textAlign: "center"
}));

const AnimatedIcon = styled(DataObject)(({ theme }) => ({
  fontSize: "8rem",
  marginBottom: theme.spacing(2),
  animation: "$pulse 2s infinite",
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)"
    },
    "50%": {
      transform: "scale(1.1)"
    },
    "100%": {
      transform: "scale(1)"
    }
  }
}));

const NotFound = ({ customBackground, customTheme, btn, height, btnTitle, btnOnClick }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container maxWidth={false} disableGutters sx={{ height: height ? height : "calc(100vh - 80px)" }}>
      <ContentWrapper sx={{ height: height ? height : "calc(100vh - 80px)", minHeight: "unset" }}>
        <Fade in={true} timeout={1000}>
          <Box>
            <AnimatedIcon
              color="primary"
              aria-hidden="true"
              sx={{ color: customTheme?.primary || theme.palette.primary.main }}
            />
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: customTheme?.text || theme.palette.text.primary
              }}
            >
              No Data Found
            </Typography>
            <Typography
              variant="h6"
              paragraph
              sx={{ color: customTheme?.text || theme.palette.text.secondary }}
            >
              We couldn't find the data you're looking for.
            </Typography>
            {btn && <Button
              variant="contained"
              color="primary"
              size="large"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => btnOnClick && btnOnClick()}
              sx={{
                mt: 2,
                backgroundColor: customTheme?.button || theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: customTheme?.buttonHover || theme.palette.primary.dark
                },
                transform: isHovered ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.3s ease-in-out"
              }}
              aria-label="Explore other areas"
            >
              {btnTitle ?? "Explore Other Areas"}
            </Button>}
          </Box>
        </Fade>
      </ContentWrapper>
    </Container>
  );
};

export default NotFound;