import { Button, DialogContent, FormControl, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import { getValues } from "../common/function/common";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";

const CriteriaForm = React.memo(({ open, onClose, type_id, view_name, form_name, selectedParty, setRows, setToastMess }) => {
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterFillAPI = usePostData(`/master_fill`);
    const partyStockCriteriaSaveAPI = usePostData(`/party_stock_criteria_save`);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');
    const [fieldsResposne, setFieldsResposne] = useState(null);
    const [selectData, setSelectData] = useState({
        shape_name: [],
        lab_name: [],
        color_name: [],
        clarity_name: [],
        cut_name: [],
        pol_name: [],
        sym_name: [],
        color_shade_name: [],
        milky_name: [],
        location_name: [],
        other_company_name: [],
    });

    const handleClose = () => {
        onClose(null);
        setError('');
        setFormData({});
    };

    const handleChange = (eventOrValue, name, type) => {
        if (type === "text") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: eventOrValue.target.value,
            }));
        } else if (type === "switch") {
            setFormData({ ...formData, [name]: eventOrValue.target.checked });
        } else if (type === "select") {
            setFormData({ ...formData, [name]: eventOrValue.target.value });
            setError("")
        }
    };

    const saveCriteria = async () => {
        const getId = (arr) => {
            return arr?.map(_ => _?.id)?.join(",");
        }

        const mapValuesToOptions = (valueString, options) => {
            const ids = valueString?.split(",").map((id) => id.trim()).filter((id) => id);
            return options?.filter((option) => ids?.includes(String(option?.id))) || [];
        };
        try {
            const responseData = await partyStockCriteriaSaveAPI.mutateAsync({
                ...formData,
                ...(formData.id && { id: formData.id }),
                party_code: selectedParty.value,
                shape_code: getId(formData.shape_name),
                lab_code: getId(formData.lab_name),
                from_carat: parseFloat(formData.from_carat),
                to_carat: parseFloat(formData.to_carat),
                color_code: getId(formData.color_name),
                clarity_code: getId(formData.clarity_name),
                cut_code: getId(formData.cut_name),
                pol_code: getId(formData.pol_name),
                sym_code: getId(formData.sym_name),
                color_shade_code: getId(formData.color_shade_name),
                milky_code: getId(formData.milky_name),
                location_code: getId(formData.location_name),
                other_company_code: getValues(formData.other_company_name),
            });
            if (responseData?.success) {
                setToastMess(responseData?.message)
                const parseData = JSON?.parse(responseData?.request_body_data)
                const updatedRow = {
                    ...parseData,
                    ...(responseData?.id && { id: responseData?.id }),
                    shape_name: mapValuesToOptions(parseData?.shape_code, selectData?.shape_name)?.map(_ => _?.label)?.join(","),
                    lab_name: mapValuesToOptions(parseData?.lab_code, selectData?.lab_name)?.map(_ => _?.label)?.join(","),
                    color_name: mapValuesToOptions(parseData?.color_code, selectData?.color_name)?.map(_ => _?.label)?.join(","),
                    clarity_name: mapValuesToOptions(parseData?.clarity_code, selectData?.clarity_name)?.map(_ => _?.label)?.join(","),
                    cut_name: mapValuesToOptions(parseData?.cut_code, selectData?.cut_name)?.map(_ => _?.label)?.join(","),
                    pol_name: mapValuesToOptions(parseData?.pol_code, selectData?.pol_name)?.map(_ => _?.label)?.join(","),
                    sym_name: mapValuesToOptions(parseData?.sym_code, selectData?.sym_name)?.map(_ => _?.label)?.join(","),
                    color_shade_name: mapValuesToOptions(parseData?.color_shade_code, selectData?.color_shade_name)?.map(_ => _?.label)?.join(","),
                    milky_name: mapValuesToOptions(parseData?.milky_code, selectData?.milky_name)?.map(_ => _?.label)?.join(","),
                    location_name: mapValuesToOptions(parseData?.location_code, selectData?.location_name)?.map(_ => _?.label)?.join(","),
                    other_company_name: selectData.other_company_name?.filter((option) =>
                        parseData?.other_company_code
                            ?.split(",")
                            .map((id) => id.trim())
                            .includes(String(option?.value))
                    )?.map(_ => _?.label)?.join(",") || [],
                };
                // Update or add the row in `setRows`
                if (parseData.id) {
                    setRows((prevData) =>
                        prevData.map((item) =>
                            item.id === parseData.id ? { ...item, ...updatedRow } : item
                        )
                    );
                } else {
                    setRows((prevData) => [...prevData, updatedRow]);
                }
                handleClose();
            } else setError(responseData?.message)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });

            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [shapCode, labCode, colorCode, clarityCode, cutCode, polishCode, symmetryCode, colorShadeCode, milkyCode, locationName, otherCompanyCode] = await Promise.all([
                fetchData("select", "shape", "shape_name"),
                fetchData("select", "lab", "lab_name"),
                fetchData("select", "color", "color_name"),
                fetchData("select", "clarity", "clarity_name"),
                fetchData("select", "cut", "cut_name"),
                fetchData("select", "pol", "pol_name"),
                fetchData("select", "sym", "sym_name"),
                fetchData("select", "color_shade", "color_shade_name"),
                fetchData("select", "milky", "milky_name"),
                fetchData("select", "diamond_location", "location_name"),
                fetchData("select", "other_company", "other_company_name"),
            ]);

            setSelectData({
                ...shapCode, ...labCode, ...colorCode, ...clarityCode, ...cutCode, ...polishCode, ...symmetryCode, ...colorShadeCode, ...milkyCode, ...locationName, ...otherCompanyCode
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchFields = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id,
                form_name,
                view_name
            });
            setFieldsResposne(responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchFields();
        fetchAllData();
    }, []);

    useEffect(() => {
        if (open?.data) {
            let values = open?.data;

            const mapValuesToOptions = (valueString, options) => {
                if (valueString && options) {
                    const ids = valueString?.split(",").map((id) => id.trim()).filter((id) => id);
                    return options?.filter((option) => ids.includes(String(option.id))) || [];
                }
            };

            setFormData({
                ...values,
                shape_name: mapValuesToOptions(values?.shape_code, selectData.shape_name),
                lab_name: mapValuesToOptions(values?.lab_code, selectData.lab_name),
                color_name: mapValuesToOptions(values?.color_code, selectData.color_name),
                clarity_name: mapValuesToOptions(values?.clarity_code, selectData.clarity_name),
                cut_name: mapValuesToOptions(values?.cut_code, selectData.cut_name),
                pol_name: mapValuesToOptions(values?.pol_code, selectData.pol_name),
                sym_name: mapValuesToOptions(values?.sym_code, selectData.sym_name),
                color_shade_name: mapValuesToOptions(values?.color_shade_code, selectData.color_shade_name),
                milky_name: mapValuesToOptions(values?.milky_code, selectData.milky_name),
                location_name: selectData.location_name?.filter((option) =>
                    values?.location_code?.split(",").map((id) => id.trim()).includes(String(option?.value))
                ) || [],
                other_company_name: selectData.other_company_name?.filter((option) =>
                    values?.other_company_code?.split(",").map((id) => id.trim()).includes(String(option?.value))
                ) || []
            });
        }
    }, [open?.data, selectData]);


    return (
        <>
            <DModal
                open={Boolean(open?.flag)}
                onClose={handleClose}
                backDrop
                DialogTitle={`Diamond`}
                DialogContent={
                    <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
                        {
                            fieldsResposne &&
                            fieldsResposne?.filter(_ => _?.is_visible_form)?.map((item, index) => {
                                const columnType = item?.column_type;
                                const required = item?.is_required;
                                const columnKey = item?.column_name;
                                const cssClassName = item?.css_class_name;
                                const headerName = item?.column_header;
                                const disabled = item?.is_editable;
                                const isFirstField = index === 1;

                                if (columnType === "text") {
                                    return (
                                        <div className={cssClassName}>
                                            <DTextField
                                                key={index}
                                                label={headerName}
                                                required={required}
                                                autoFocus={isFirstField}
                                                value={formData[columnKey] || ''}
                                                type={(columnKey === "from_carat" || columnKey === "to_carat") ? "number" : "text"}
                                                onChange={(e) => handleChange(e, columnKey, columnType)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                disabled={!disabled}
                                            />
                                        </div>
                                    );
                                }
                                if (columnType === 'select') {
                                    let options = selectData[columnKey];
                                    const selectedOption = options && Array.isArray(formData[columnKey])
                                        ? options.filter(option => formData[columnKey]?.some(item => item?.value === option?.value))
                                        : [];

                                    const handleSelectionChange = (newValue) => {
                                        const selectedValues = newValue.map(option => option);
                                        handleChange({ target: { value: selectedValues } }, columnKey, columnType);
                                    };

                                    return (
                                        <div className={cssClassName}>
                                            <FormControl fullWidth key={columnKey}>
                                                <MultipleSelectCheckmarks
                                                    required={required}
                                                    options={options}
                                                    autoFocus={isFirstField}
                                                    value={selectedOption || null}
                                                    handleChange={handleSelectionChange}
                                                    headerName={headerName}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                }
                                if (columnType === "switch") {
                                    return (
                                        <div className={cssClassName} >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={formData[columnKey] || false}
                                                        onChange={(e) => handleChange(e, columnKey, columnType)}
                                                    />
                                                }
                                                label={headerName}
                                            />
                                        </div>
                                    );
                                }
                            })
                        }
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </DialogContent>
                }
                DialogActions={
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" autoFocus onClick={saveCriteria}>Save</Button>
                    </>
                }
            />
        </>
    );
});

export default CriteriaForm;