import React, { lazy, useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { usePostData } from "../common/function/api";
import BranchCompanyModal from "../components/branch-company-modal";
import ConfirmationModal from "../components/confirmationModal";
import Loader from "../components/loader";

const MRTable = lazy(() => import("../components/MRTable"));

const Branch = React.memo(({ type_id, form_name }) => {
  const saveAPI = usePostData(`/branch_save`);
  const deleteAPI = usePostData(`/branch_delete`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const masterViewAPI = usePostData(`/master_view`);
  const masterFillAPI = usePostData(`/master_fill`);
  const [columnResponse, setColumnResponse] = useState(null);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [rows, setRows] = useState([]);
  const [loadComponent, setLoadComponent] = useState(false);
  const [formData, setFormData] = useState({ branch_code: '', branch_name: '', is_active: false, branch_id: '', company_name: '' });
  const [companyData, setCompanyData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  const [newKey, setNewKey] = useState(0);

  // Function to handle creating a new row
  const saveBranch = ({ values, table }) => {
    saveAPI.mutate({
      branch_data: {
        ...formData,
        company_id: formData?.company_name,
        branch_code: formData?.branch_code,
        branch_name: formData?.branch_name,
        is_active: formData?.is_active || false,
        ...(formData?.branch_id && { branch_id: formData?.branch_id })
      }
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          const parseData = JSON?.parse(responseData?.request_body_data)?.branch_data;
          if (formData?.branch_id) {
            const updatedCompanyName = companyData?.find((company) => company?.value === parseData?.company_id)?.label || '';
            setRows((prevData) =>
              prevData.map((item) =>
                item.branch_id === formData?.branch_id
                  ? {
                    ...item,
                    ...formData,
                    company_id: parseData?.company_id,
                    company_name: updatedCompanyName,
                    branch_code: formData?.branch_code,
                    branch_name: formData?.branch_name,
                    is_active: formData?.is_active
                  }
                  : item
              )
            );
            setFormData({});
            setEditingRow(null);
            table.setEditingRow(null);
            setToastMess(responseData)
          } else {
            setRows((prevData) => [...prevData, {
              ...formData,
              company_id: parseData?.company_id,
              company_name: companyData?.find((_) => _?.value === parseData?.company_id)?.label,
              branch_code: formData?.branch_code,
              branch_name: formData?.branch_name,
              is_active: formData?.is_active,
              branch_id: responseData?.branch_id
            }]);
            setFormData({});
            table.setCreatingRow(null);
            setToastMess(responseData)
          }
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      }
    });
  }

  const deleteConfirmation = async (id) => {
    deleteAPI.mutate({ branch_id: id }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.branch_id !== id));
          setOpenDeletemodal({ flag: false, data: null });
          setToastMess(responseData)
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleChange = (event, columnId, columnType) => {
    if (columnType === "select") {
      setFormData({ ...formData, [columnId]: event.target.value })
    } else if (columnType === "switch") {
      setFormData({ ...formData, [columnId]: event.target.checked })
    } else {
      setFormData({ ...formData, [columnId]: event.target.value })
    }
  };

  useEffect(() => {
    const fetchColumn = () => {
      viewColumnsFillAPI.mutate({
        type_id: type_id,
        form_name: form_name,
      }, {
        onSuccess: (responseData) => {
          if (responseData) {
            setColumnResponse(responseData)
          }
        },
        onError: (error) => {
          console.error('Error:', error);
        }
      });
    };

    const fetchBranchData = () => {
      masterViewAPI.mutate({
        view_name: form_name,
      }, {
        onSuccess: (responseData) => {
          if (responseData) {
            const rowsData = responseData.map((row, index) => ({
              id: index + 1,
              ...row,
              is_active: row?.is_active
            }));
            setRows(rowsData);
            setLoadComponent(true)
          }
        },
        onError: (error) => {
          console.error('Error:', error);
        }
      });
    };

    const fetchCompanyData = () => {
      masterFillAPI.mutate({
        "mast_name": "company",
        "mast_type": "select"
      }, {
        onSuccess: (responseData) => {
          if (responseData) {
            setCompanyData(responseData)
          }
        },
        onError: (error) => {
          console.error('Error:', error);
        }
      });
    };

    fetchColumn();
    fetchBranchData();
    fetchCompanyData();
  }, [type_id, form_name]);

  const columns =
    columnResponse &&
    columnResponse.map((col) => ({
      accessorKey: col.column_name,
      header: col.column_header,
      size: col.column_width,
      filterVariant: 'autocomplete',
      flex: 1,
      filterFn: 'includesString',
      is_visible: col?.is_visible,
      is_visible_form: col?.is_visible_form,
      is_required: col?.is_required,
      css_class_name: col?.css_class_name,
      column_type: col?.column_type,
      muiTableBodyCellProps: ({ cell }) => ({
        // Custom rendering logic for the is_active column
        children:
          col.column_name === "is_active" ? (
            <label className="label toggle">
              <input type="checkbox" readOnly checked={cell.getValue()} className="toggle_input" />
              <div className="toggle-control"></div>
            </label>
          ) : (
            cell.getValue()
          ),
      }),
    }));

  useEffect(() => {
    setVisibleColumn(columns && columns?.reduce((acc, column) => {
      if (column.accessorKey) {
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const renderDialogContent = (props, type) => (
    <BranchCompanyModal
      {...props}
      type={type}
      formData={formData}
      setFormData={setFormData}
      handleChange={handleChange}
      companyData={companyData}
      error={error}
      newKey={newKey}
      setNewKey={setNewKey}
      addressModal
    />
  );
  useEffect(() => {
    if (editingRow) {
      const preparedData = {
        company_id: editingRow.original?.company_id,
        company_name: editingRow.original?.company_id,
        branch_code: editingRow.original?.branch_code,
        branch_name: editingRow.original?.branch_name,
        is_active: editingRow.original?.is_active,
        branch_id: editingRow.original?.branch_id,
      }
      setFormData(preparedData);
    }
  }, [editingRow]);
  return (
    <React.Fragment>
      <div style={{ height: "100%", width: "100%" }}>
        {(loadComponent && masterViewAPI?.isSuccess) ?
          <MRTable
            columns={columns || []}
            visibleColumn={visibleColumn}
            rows={rows || []}
            formData={formData}
            setFormData={setFormData}
            editingRow={editingRow}
            setEditingRow={setEditingRow}
            createFunction={saveBranch}
            editFunction={saveBranch}
            openDeletemodal={openDeletemodal}
            setOpenDeletemodal={setOpenDeletemodal}
            deleteConfirmation={deleteConfirmation}
            toastMess={toastMess}
            setToastMess={setToastMess}
            error={error}
            setError={setError}
            maxHeight="840px"
            renderCreateRowDialogContent={(e) => renderDialogContent(e, "create")}
            renderEditRowDialogContent={(e) => renderDialogContent(e, "edit")}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                  <IconButton sx={{ padding: 0 }} onClick={() => {
                    setEditingRow(row);
                    table.setEditingRow(row);
                  }}>
                    <Edit sx={{ height: '16px', width: '16px' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip sx={{ padding: 0 }} title="Delete">
                  <IconButton color="error" onClick={() => setOpenDeletemodal({ flag: true, data: row?.original })}>
                    <Delete sx={{ height: '16px', width: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          /> :
          <Loader />}
      </div>

      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Branch?"}
        title={
          <>Are you sure you want to delete <strong>"{openDeletemodal?.data?.branch_name}"</strong> branch?</>
        }
        action={() => deleteConfirmation(openDeletemodal?.data?.branch_id)}
      />
    </React.Fragment>
  );
});

export default Branch;