import React, { useEffect, useState } from "react"
import { usePostData } from "../common/function/api";
import ReactPivotGrid from "../components/react-pivot-grid";
import Loader from "../components/loader";

const StockView = (props) => {
    const stockViewAPI = usePostData(`/stock_view`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const [data, setData] = useState(null);
    const [columns, setColumn] = useState(null);
    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "stock_view"
            });
            setColumn(responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRows = async () => {
        try {
            const responseData = await stockViewAPI.mutateAsync({});
            setData(responseData)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchRows();
    }, []);
    return (
        data && columns ? <ReactPivotGrid columns={columns} data={data} className="d_stock-view"/> : <Loader/>
    )
};

export default StockView;
