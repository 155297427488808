import { Search } from "@mui/icons-material";
import { Box, Button, FormControl } from "@mui/material";
import PivotGrid, {
    Export,
    FieldChooser,
    FieldPanel,
    Scrolling,
} from "devextreme-react/pivot-grid";
import { exportPivotGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import Loader from "../components/loader";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";

const StockLevelView = (props) => {
    const pivotGridRef = useRef(null);
    const masterFillAPI = usePostData(`/master_fill`);
    const rapViewAPI = usePostData(`/stock_level_view`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const [data, setData] = useState(null);
    const [columns, setColumn] = useState(null);
    const [toastMess, setToastMess] = useState(null);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        other_company_name: []
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                mast_type: mastType,
                mast_name: mastName,
            });

            // Ensure unique options
            const uniqueData = Array.from(
                new Map(responseData.map(item => [item.label, item])).values()
            );

            return { [key]: uniqueData };
        } catch (error) {
            console.error("Error:", error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        setLoading(true)
        try {
            const [otherCompanyName] = await Promise.all([
                fetchData("select", "other_company", "other_company_name"),
            ]);
            setLoading(false);
            setOptions({ ...otherCompanyName });
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const fetchColumn = async () => {
        setLoading(true);
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "stock_level_view"
            });
            setColumn(responseData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const fetchRowData = async (company_id) => {
        setLoading(true);
        try {
            const responseData = await rapViewAPI.mutateAsync({
                company_id: company_id?.map(_ => _?.value)?.join(",")
            });
            setData(responseData)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAllData();
        fetchColumn();
    }, []);

    const handleChange = (newValue, name) => {
        setFormData({ ...formData, [name]: newValue?.target?.value });
        setError("")
    }

    const handleSelectionChange = (newValue) => {
        const selectedValues = newValue.map(option => option);
        handleChange({ target: { value: selectedValues } }, "company_id");
    };

    const summaryDisplayModes = [
        { text: "None", value: "none" },
        { text: "Absolute Variation", value: "absoluteVariation" },
        { text: "Percent Variation", value: "percentVariation" },
        { text: "Percent of Column Total", value: "percentOfColumnTotal" },
        { text: "Percent of Row Total", value: "percentOfRowTotal" },
        { text: "Percent of Column Grand Total", value: "percentOfColumnGrandTotal" },
        { text: "Percent of Row Grand Total", value: "percentOfRowGrandTotal" },
        { text: "Percent of Grand Total", value: "percentOfGrandTotal" },
    ];

    const fields = columns && columns.map((col) => {

        const validSummaryDisplayMode = summaryDisplayModes.some(
            (mode) => mode.value === col.summary_type
        )
            ? col.summary_type
            : "none"; // Default to "none" if invalid or missing

        return {
            ...col,
            area: col.field_area,
            dataField: col.column_name,
            caption: col.column_header,
            area: col.field_area,
            dataType: col.column_type === "text" ? "string" : "number",
            format: col.column_format,
            width: col.column_width,
            alignment: col.column_align === 1 ? "left" : "right",
            visible: col.is_visible,
            summaryDisplayMode: validSummaryDisplayMode,
            allowSorting: true,
            allowFiltering: true,
            allowExpandAll: true,
            ...(col?.column_format && { format: { type: 'fixedPoint', precision: col?.column_format } }),
            customizeText: (e) => {
                return col.is_text_upper ? e.valueText?.toUpperCase() : e.valueText;
            },
            summaryType: validSummaryDisplayMode?.includes("percent") ? "sum" : col.summary_type || null,
            expanded: true,
        };
    });
    const onExporting = (e) => {
        if (data?.length) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("PivotGrid");

            e.cancel = true; // Cancel default export to use custom implementation

            exportPivotGrid({
                component: pivotGridRef.current.instance,
                worksheet,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "PivotGrid.xlsx"
                    );
                });
            });
        }
    };

    return (
        <React.Fragment>
            <Box sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="d_row" style={{ width: "100%" }}>
                    <div className="d_col-3">
                        <FormControl fullWidth>
                            <MultipleSelectCheckmarks
                                options={options?.other_company_name}
                                value={formData?.company_id || []}
                                handleChange={handleSelectionChange}
                                headerName={"Party"}
                            />
                        </FormControl>
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </div>
                </div>
                <div className="d_flex-align" style={{ gap: "10px" }}>
                    <Button variant="contained" onClick={() => fetchRowData(formData?.company_id)} disabled={loading} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
                </div>
            </Box>
            {!loading ?
                <PivotGrid
                    ref={pivotGridRef}
                    dataSource={{
                        fields: fields,
                        store: data,
                    }}
                    allowSortingBySummary={true}
                    allowFiltering={true}
                    showBorders={true}
                    height="calc(90% - 50px)"
                    rowHeaderLayout="standard"
                    className="d_rap-view d_stock-level"
                    onExporting={onExporting}
                    showColumnTotals={false}
                    showRowTotals={false}
                >
                    <Scrolling mode="virtual" />
                    <Export enabled={true} />
                    <FieldChooser enabled={true} />

                    <FieldChooser enabled={true} />
                    <FieldPanel showColumnFields={false} showDataFields={false} showFilterFields={true} allowFieldDragging={true} visible={true} />
                </PivotGrid>
                : <Loader />
            }
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    )
};

export default StockLevelView;