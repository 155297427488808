import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

export function createLabelValue(label, value) {
  return { label: label, value: value };
}

export function getLabels(arr) {
  return arr?.map(_ => _?.label)?.join(",");
}

export function getValues(arr) {
  return arr?.map(_ => _?.value)?.join(",");
}

export const formatDate = (date, format) => {
  return date ? dayjs(date).format(format ? format : 'DD/MM/YYYY') : "";
};

export function getLocalStorage(key) {
  const token = localStorage.getItem(key);

  if (!token) {
    return null; // Return null if no token is found
  }

  try {
    const decodedData = JSON.parse(atob(token)); // Decode base64 and parse JSON
    return decodedData;
  } catch (error) {
    console.error("Error decoding or parsing the token", error);
    return null; // Handle potential errors gracefully
  }
}

export function getPartyDataFromUrl(paramName = 'data') {
  const query = new URLSearchParams(window.location.search);
  const encodedData = query.get(paramName);

  if (encodedData) {
    try {
      const decodedData = atob(encodedData); // Base64 decode
      return JSON.parse(decodedData);
    } catch (error) {
      console.error("Failed to decode or parse data:", error);
    }
  }

  return null; // Return null if no data is found or an error occurs
}

export function getExtensionFromBase64(base64String) {
  // Check if base64String is valid
  if (typeof base64String !== 'string' || !base64String) {
    // console.error('Invalid input: base64String must be a non-empty string.');
    return 'unknown';
  }

  // Split the base64 string to get the MIME type
  const mimeTypeStart = base64String.indexOf(":") + 1;
  const mimeTypeEnd = base64String.indexOf(";");

  // Check if the MIME type is found
  if (mimeTypeStart === -1 || mimeTypeEnd === -1) {
    console.error('Invalid base64 string: MIME type not found.');
    return 'unknown';
  }

  let mimeType = base64String.substring(mimeTypeStart, mimeTypeEnd);

  // Use the MIME type to find the file extension
  const mimeTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/svg+xml': 'svg',
    'application/pdf': 'pdf',
    // Add more MIME types as needed
  };

  return mimeTypes[mimeType] || 'unknown';
}

// Common encryption function
export const encryptText = (text, key = "DtLaBGrTecH@2024") => {
  const keyBytes = CryptoJS.enc.Utf8.parse(key.padEnd(16, ' '));
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the text
  const encrypted = CryptoJS.AES.encrypt(text, keyBytes, { iv: iv, padding: CryptoJS.pad.Pkcs7 }).ciphertext;

  // Combine IV and ciphertext
  const fullCipher = CryptoJS.lib.WordArray.create();
  fullCipher.concat(iv).concat(encrypted);

  // Convert to Base64 and return
  return fullCipher.toString(CryptoJS.enc.Base64);
};

// Common decryption function
export const decryptText = (ciphertext, key = "DtLaBGrTecH@2024") => {
  // Convert from Base64 to WordArray
  const fullCipher = CryptoJS.enc.Base64.parse(ciphertext);

  // Extract the IV (first 16 bytes)
  const iv = CryptoJS.lib.WordArray.create(fullCipher.words.slice(0, 4)); // 16 bytes / 4 = 4 words
  const cipher = CryptoJS.lib.WordArray.create(fullCipher.words.slice(4)); // Remaining words are the ciphertext

  // Ensure the key length is 16 bytes for AES-128
  const keyBytes = CryptoJS.enc.Utf8.parse(key.padEnd(16, ' ')); // Pad to 16 bytes

  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipher }, keyBytes, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const convertToDayjs = (dateStr) => {
  if (!dateStr) return null;
  const [day, month, year] = dateStr.split('-');
  return dayjs(`${year}-${month}-${day}`);
};