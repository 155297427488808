import { Add } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import ConfirmationModal from "../components/confirmationModal";
import CriteriaForm from "./criteria-form";

const PartyStockCriteria = (props) => {
    const masterFillAPI = usePostData(`/master_fill`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/master_view`);
    const deleteAPI = usePostData(`/party_stock_criteria_delete`);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        party_name: []
    });
    const [visibleColumn, setVisibleColumn] = useState({});
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState({ flag: false, data: null });
    const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
    const [toastMess, setToastMess] = useState("");
    const [error, setError] = useState("");
    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                mast_type: mastType,
                mast_name: mastName,
            });

            // Ensure unique options
            const uniqueData = Array.from(
                new Map(responseData.map(item => [item.label, item])).values()
            );

            return { [key]: uniqueData };
        } catch (error) {
            console.error("Error:", error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [partyName] = await Promise.all([
                fetchData("select", "party", "party_name"),
            ]);
            setOptions({ ...partyName });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "party_stock_criteria"
            });
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
                is_editable: col?.is_editable,
                cell: (cell) => (
                    ["switch"].includes(col.column_type) ? (
                        <label className="label toggle">
                            <input type="checkbox" readOnly checked={cell.getValue()} className="toggle_input" />
                            <div className="toggle-control"></div>
                        </label>
                    ) : (
                        cell.getValue()
                    )
                )
            }));
            const actionsColumn = {
                accessorKey: 'actions',
                header: 'Actions',
                size: 100, // Adjust size if needed
                textAlign: 'center',
                columnType: 'action', // Custom type
                is_visible: true,
                left: 0
            };
            columns.splice(0, 0, actionsColumn);
            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRowData = async (party_code) => {
        try {
            const responseData = await masterViewAPI.mutateAsync({
                party_code: party_code,
                view_name: "party_stock_criteria",
            });

            if (responseData) {
                const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({ ...row, })) : [];

                setRows(rowsData);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAllData();
        fetchColumn();
    }, []);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    const handleChange = (newValue, name) => {
        setFormData({ ...formData, [name]: newValue });
        fetchRowData(newValue?.value)
        setError("")
    }

    const handleAddEdit = (data) => {
        if (!formData?.party) {
            setError("Please select party.")
        } else if (data?.id) {
            setOpen({ flag: true, data: data });
            setError("");
        } else {
            setOpen({ flag: true, data: null });
            setError("");
        }
    };

    const deleteConfirmation = async (id) => {
        try {
            const responseData = await deleteAPI.mutateAsync({
                id: id?.id,
                party_code: id?.party_code
            });

            if (responseData?.success) {
                setRows((prevData) => prevData.filter((item) => item.id !== id?.id));
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message); // Assuming responseData contains a message
            } else {
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message); // Assuming responseData contains a message
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <React.Fragment>
            <Box sx={{ p: 2 }}>
                <div className="d_row" style={{ width: "100%" }}>
                    <div className="d_col-3">
                        <FormControl fullWidth>
                            <Autocomplete
                                value={formData?.party || null}
                                onChange={(event, newValue) => handleChange(newValue, 'party')}
                                options={options?.party_name || []}
                                getOptionLabel={(option) => option?.label ?? ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Party"}
                                    />
                                )}
                                size="small"
                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            />
                        </FormControl>
                        {error && <em style={{color: "red"}}>{error}</em>}
                    </div>
                </div>
            </Box>
            {(columnResponse && visibleColumn !== null) &&
                <VirtualizedTableData
                    style={{ margin: 0 }}
                    height={`calc(100vh - 35px)`}
                    data={rows} visibleColumn={visibleColumn}
                    loading={!viewColumnsFillAPI?.isPending}
                    columnResponse={columnResponse} tfoot={false}
                    uploadExcel={<Box><Button onClick={handleAddEdit} variant="contained" startIcon={<Add />}>Add</Button></Box>}
                    handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
                    handleEdit={(data) => handleAddEdit(data)}
                    rowSelection={false} rowCount={rows?.length}
                />
            }
            {open?.flag && <CriteriaForm
                fields={columnResponse}
                open={open}
                selectedParty={formData?.party}
                onClose={() => setOpen({ flag: false, data: null })}
                type_id={props?.type_id}
                form_name={props?.form_name}
                view_name="party_stock_criteria"
                setRows={setRows}
                setToastMess={setToastMess}
            />}
            <ConfirmationModal
                open={openDeletemodal?.flag}
                onClose={() => setOpenDeletemodal({ flag: false, data: null })}
                header={"Delete Party?"}
                title={
                    <>Are you sure you want to delete the criteria?</>
                }
                action={() => deleteConfirmation(openDeletemodal?.data)}
            />
            {toastMess && <ToastBar open={toastMess} onClose={setToastMess} />}
        </React.Fragment>
    )
};

export default PartyStockCriteria;
