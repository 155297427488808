import React, { useState, forwardRef, memo, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  HeaderFilter,
  Paging,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import Loader from "./loader";
import { Toolbar } from "devextreme-react";

const DataGridComponent = memo(
  forwardRef((props, ref) => {
    const {
      data,
      keyExpr,
      onSelectionChanged,
      selectedRows,
      columns,
      stickyIndex,
      summaryItems,
      extraColumn,
      cellRender,
      height,
      loading,
      calculateSummary
    } = props;

    const [filterVisible, setFilterVisible] = useState(false); // State to control filter visibility
    const dataGridRef = useRef(null);
    useImperativeHandle(ref, () => ({
      clearFiltersAndSorting: () => {
        if (dataGridRef.current) {
          dataGridRef.current.instance.clearFilter();
          dataGridRef.current.instance.clearSorting();
        }
      },
    }));

    const handleToggleFilter = () => {
      setFilterVisible((prev) => !prev); // Toggle filter visibility
    };

    const debounce = (func, delay) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
      };
    };

    const handleGridScroll = debounce(() => {
      console.log("Grid is scrolling...");
    }, 100);

    const memoizedData = React.useMemo(() => data, [data]);

    // Memoize columns (if an extra column is provided)
    const memoizedColumns = React.useMemo(() => {
      return extraColumn ? [extraColumn, ...(Array.isArray(columns) ? columns : [])] : columns;
    }, [extraColumn, columns]);

    const memoizedCellRender = React.useCallback((e, col) => {
      if (cellRender) {
        return cellRender(e, col);
      }
    }, [cellRender]);

    // Detect if the screen is mobile
    const isMobile = React.useMemo(() => window.innerWidth <= 768, []);
    const deferredColumns = React.useDeferredValue(columns);
    const onToolbarPreparing = (e) => {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "filter", // Use a different icon based on the filter visibility state
            onClick: () => {
              handleToggleFilter();
              if (filterVisible) {
                dataGridRef.current.instance.clearFilter();
              }
            },
            elementAttr: { class: `custom-toggle-filter-button ${filterVisible ? "active" : ""}` }, // Add custom CSS class for styling
            hint: filterVisible ? "Clear Filter" : "Apply Filter", // Optional: Tooltip text based on the state
            stylingMode: "text", // Optional: Styling for text button (you can remove it if you prefer an icon only)
          },
        }
      );
    };

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative" }}>
        {/* DataGrid Wrapper */}
        <div style={{ position: "relative", height: height || "100%", width: "100%" }}>
          {/* Loader Overlay */}
          {!loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 111,
              }}
            >
              <Loader />
            </div>
          )}

          <DataGrid
            ref={dataGridRef}
            dataSource={data}
            animation={{ enabled: false }}
            keyExpr={keyExpr}
            showBorders={true}
            allowColumnResizing={false}
            columnAutoWidth={true}
            height="100%"
            onSelectionChanged={(e) => onSelectionChanged(e?.selectedRowKeys)}
            selectedRowKeys={selectedRows}
            showColumnLines={true}
            // remoteOperations={true}
            onToolbarPreparing={onToolbarPreparing}
            onScroll={handleGridScroll}
            onExporting={(e) => {
              const workbook = new ExcelJS.Workbook();
              const worksheet = workbook.addWorksheet("Sheet1");
              exportDataGridToExcel({
                component: e.component,
                worksheet: worksheet,
              }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                  saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGridExport.xlsx");
                });
              });

              e.cancel = true;
            }}   
            hoverStateEnabled={true}
          >
            <HeaderFilter visible={filterVisible}><Search enabled={true} /></HeaderFilter>
            <ColumnChooser enabled />
            {props?.rowSelection && <Selection mode="multiple" showCheckBoxesMode="always" />}
            <Scrolling mode="virtual" />
            <Paging enabled={false} />
            <Sorting mode="multiple" />
            <SearchPanel visible highlightCaseSensitive />
            <Export enabled />
            {Array.isArray(deferredColumns) && deferredColumns.map((col, index) => {
              return (
                <Column
                  key={index}
                  fixed={!isMobile && index < stickyIndex}
                  fixedPosition="left"
                  dataField={col.accessorKey}
                  caption={col.header}
                  visible={col.is_visible}
                  alignment={col.textAlign}
                  cellRender={col.cellRender}
                  allowFiltering={true}
                  allowSorting={true}
                  format={col?.format}
                />
              );
            })}
            {/* <Summary>
              <TotalItem
                column="diamond_id"
                summaryType="count"
              />
            </Summary> */}
            <Summary>
              {summaryItems.map((item, index) => (
                <TotalItem
                  key={index}
                  name={item.column}
                  column={item.column}
                  summaryType={item.summaryType}
                  displayFormat={item.displayFormat}
                  skipEmptyValues={false}
                />
              ))}
            </Summary>
          </DataGrid>
        </div>
      </div>
    );
  })
);

// Define PropTypes
DataGridComponent.propTypes = {
  data: PropTypes.array,
  keyExpr: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  selectedRows: PropTypes.array,
  columns: PropTypes.array,
  stickyIndex: PropTypes.number,
  summaryItems: PropTypes.array,
  loading: PropTypes.bool,
  cellRender: PropTypes.func,
};

// Set default props to avoid crashes
DataGridComponent.defaultProps = {
  data: [],
  keyExpr: "diamond_id",
  onSelectionChanged: () => { },
  selectedRows: [],
  columns: [],
  stickyIndex: 0,
  summaryItems: [],
  loading: false,
  cellRender: null
};

export default DataGridComponent;
