import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { usePostData } from "../common/function/api";
import { getLocalStorage } from "../common/function/common";
import DModal from "./DModal";

const PasswordChange = (props) => {
    const userData = getLocalStorage("userAuth");
    const changePasswordAPI = usePostData(`/employee_change_password_save`);

    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const [fieldValue, setFieldValue] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handle = {
        change: (value, name) => {
            setFieldValue({ ...fieldValue, [name]: value });
        },
        handleClickShowPassword: (field) => {
            setShowPassword((prevState) => ({
                ...prevState,
                [field]: !prevState[field],
            }));
        },
        resetFields: () => {
            setFieldValue({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            });
            setError("");
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError("");

        // Check if current password matches userData?.password
        if (fieldValue.currentPassword !== userData?.password) {
            setError("Current password is incorrect.");
            return;
        }

        // Validate password match
        if (fieldValue.newPassword !== fieldValue.confirmPassword) {
            setError("New Password and Confirm Password do not match.");
            return;
        }

        setLoading(true);

        // Password change API call
        changePasswordAPI.mutate(
            {
                id: userData?.user_id,
                erp_user: userData?.email,
                erp_pass: fieldValue.newPassword,
            },
            {
                onSuccess: (responseData) => {
                    setLoading(false);
                    if (responseData?.success) {
                        props?.setSuccessMessage("Password changed successfully!");
                        handle.resetFields(); // Reset fields after success
                        props?.onClose();
                    } else {
                        setError(responseData?.message || "Failed to change password.");
                    }
                },
                onError: (error) => {
                    setLoading(false);
                    console.error("Error:", error);
                    setError("Failed to change password. Please try again.");
                },
            }
        );
    };

    return (
        <React.Fragment>
            <DModal
                open={props?.open}
                onClose={() => {
                    handle.resetFields(); // Reset fields when modal is closed
                    props?.onClose();
                }}
                DialogTitle="Change Password"
                modalWidth={500}
                backDrop
                DialogContent={
                    <DialogContent className="d_row" sx={{ padding: "10px 35px !important" }}>
                        <FormControl sx={{ width: "100%" }} required variant="outlined" margin="normal">
                            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email"
                                type="email"
                                value={userData?.email}
                                readOnly
                                disabled
                                label="Email"
                            />
                        </FormControl>

                        <FormControl sx={{ width: "100%" }} required variant="outlined" margin="normal">
                            <InputLabel htmlFor="outlined-adornment-current-password">Current Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-current-password"
                                type={showPassword.currentPassword ? "text" : "password"}
                                value={fieldValue.currentPassword}
                                onChange={(e) => handle.change(e.target.value, "currentPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton title={showPassword.currentPassword ? "Hide Password" : "Show Password"}
                                            aria-label="toggle current password visibility"
                                            onClick={() => handle.handleClickShowPassword("currentPassword")}
                                            edge="end"
                                        >
                                            {showPassword.currentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoComplete="off"
                                label="Current Password"
                            />
                        </FormControl>

                        <FormControl sx={{ width: "100%" }} required variant="outlined" margin="normal">
                            <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-new-password"
                                type={showPassword.newPassword ? "text" : "password"}
                                value={fieldValue.newPassword}
                                onChange={(e) => handle.change(e.target.value, "newPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton title={showPassword.newPassword ? "Hide Password" : "Show Password"}
                                            aria-label="toggle new password visibility"
                                            onClick={() => handle.handleClickShowPassword("newPassword")}
                                            edge="end"
                                        >
                                            {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoComplete="off"
                                label="New Password"
                            />
                        </FormControl>

                        <FormControl sx={{ width: "100%" }} required variant="outlined" margin="normal">
                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                type={showPassword.confirmPassword ? "text" : "password"}
                                value={fieldValue.confirmPassword}
                                onChange={(e) => handle.change(e.target.value, "confirmPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton title={showPassword.confirmPassword ? "Hide Password" : "Show Password"}
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => handle.handleClickShowPassword("confirmPassword")}
                                            edge="end"
                                        >
                                            {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoComplete="off"
                                label="Confirm Password"
                            />
                        </FormControl>

                        {error && (
                            <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
                                {error}
                            </Typography>
                        )}

                        {props?.successMessage && (
                            <Typography color="primary" variant="body2" sx={{ marginTop: 2 }}>
                                {props?.successMessage}
                            </Typography>
                        )}
                    </DialogContent>
                }
                DialogActions={
                    <>
                        <Button
                            onClick={() => {
                                handle.resetFields();
                                props?.onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={loading} onClick={handleSubmit}>
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                        </Button>
                    </>
                }
            />
        </React.Fragment>
    );
};

export default PasswordChange;
